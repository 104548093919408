$breakpoint-tablet: 1200px;
$transition: 200ms all linear;
$header-background: url("https://images.unsplash.com/photo-1465893359074-d3b7949d606d?ixlib=rb-1.2.1&auto=format&fit=crop&w=1740&q=80")
  no-repeat top center;
$header-height: 100vh;

$font-family: "FFBauWebProRegular", sans-serif;

$color: black;
$background: rgba(255, 255, 255, 0.75);
$sticky-background: #444;
$border-color: white;
$mobile-background: rgba(255, 255, 255, 0.2);
$mobile-color: white;

// menu links
$link-width: 150px;
$link-padding: 20px 0 20px 20px;
$link-separator: solid 1px $border-color;
$link-color: black;
$link-color-hover: #444;
$link-background: transparent;
$link-active-color: white;
$link-active-background: #444;
$link-active-separator: none;
$sticky-link-color: white;
$sub-link-color: black;
$sub-link-hover-color: #444;
$sub-link-padding: 0.75rem 0;

// menus
$menu-offset-top: 67px;
$menu-background: $background;
$menu-width: 1200px;
$menu-padding: 3rem;

// mobile menu
$mobile-menu-width: 320px;
$hamburger-color: #00818a;
$mobile-menu-background: #373E41;
$mobile-link-color: white;
$mobile-link-background: #373E41;
$mobile-arrow-color: white;
$mobile-arrow-size: 2rem;
$mobile-sub-link-color: white;
$mobile-sub-link-padding-vertical: 1rem;
$mobile-sub-link-padding-horizontal: 2rem;

body {
  overflow-x: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

header {
  height: $header-height;
  position: relative;

  .container {
    width: 100%;
    max-width: $breakpoint-tablet;
    margin: auto;
    @media all and (max-width: $breakpoint-tablet) {
      width: 100%;
    }
  }
}

nav {
  background: $background;
  color: $color;
  transition: 0.5s all ease;
  z-index: 100;
  //position: absolute;
  width: 100%;
  top: 80px;
  right: 0;
  left: 0;
  z-index: 100;

  @media all and (max-width: $breakpoint-tablet) {
    background: $mobile-background;
    color: $mobile-color;
    padding: 1rem;
    //position: fixed;
    top: 0;
  }

  a,
  a:visited,
  a:active {
    color: $link-color;
    transition: $transition;

    @media all and (max-width: $breakpoint-tablet) {
      color: $mobile-link-color;
    }

    &:hover {
      
    }
  }

  .logo {
    display: block;
    max-width: 250px;
    padding: 1rem;
    transition: $transition;
    opacity: 1;
    @media all and (max-width: $breakpoint-tablet) {
      padding: 0;
    }
  }

  .mobile-menu {
    transition: $transition;
    @media all and (max-width: $breakpoint-tablet) {
      position: fixed;
      z-index: 999;
      top: 0;
      bottom: 0;
      right: -$mobile-menu-width;
      width: $mobile-menu-width;
      height: 100%;
      background: $mobile-menu-background;
      overflow-y: scroll;
      overflow-x: hidden;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    }
  }

  #mega-menu {
    margin: 0 0 0 auto;
    position: relative;
    width: $menu-width;

    @media all and (max-width: $breakpoint-tablet) {
      margin: 0 1rem 0 auto;
      width: 100%;
      padding: 4rem 0 2rem 0;
      background: $mobile-link-background;
    }

    // mega menu drop down styles
    .droplet {
      display: none;
      position: absolute;
      width: $menu-width;
      max-width: 100%;
      top: $menu-offset-top;
      left: -250px;
      background-color: #FFF;
      z-index: 2000;
      font-weight: 600;
      padding: $menu-padding;
      text-align: left;
      
        p {
            font-weight: 400;
            font-family: "FFBauWebProRegular", sans-serif;
        }
        
        a {
            color: $teal;
            font-size: 18px;
            font-family: "FFBauWebProRegular", sans-serif;
            width: 100%;
            display: block;
            padding: 5px;
            line-height: 1;
            
            &:hover {
                background: $lighterTeal;
            }
            
            @media all and (max-width: $breakpoint-tablet) {
              color: $lightTeal;
            }
        }

      @media all and (max-width: $breakpoint-tablet) {
        display: block;
        overflow: scroll;
        background-color: #373E41;
        position: fixed;
        width: $mobile-menu-width;
        top: 0;
        bottom: 0;
        right: -$mobile-menu-width;
        left: auto;
        min-height: 100vh;
        transition: $transition;
        border: none;
        &.active {
          right: 0;
        }
      }

      ul {
        width: 100%;
        margin: 0;
     

        li {
          width: 100%;
          margin: 0;

        }
      }
    }

    // main mega menu link styles
    li {
      list-style-type: none;
      display: inline-block;
      margin: 0 -0.135rem;
      border-right: $link-separator;

      @media all and (max-width: $breakpoint-tablet) {
        width: 100%;
        padding: 0.5rem 2rem 0.5rem 0;
        position: relative;
        font-family: FontAwesome, serif;
        &:after {
          content: "\203A";
          color: $mobile-arrow-color;
          font-size: $mobile-arrow-size;
          position: absolute;
          top: 2px;
          bottom: 0;
          right: 2rem;
        }
      }

      .mega-menu-link {
        cursor: pointer;
        font-size: 0.8rem;
        letter-spacing: 0.1rem;
        font-family: $font-family;
        text-transform: uppercase;
        line-height: 0.85rem;
        font-weight: 600;
        position: relative;
        display: block;
        text-align: center;
        width: 100%;
        padding: 20px 8px;
        background-color: $link-background;
        text-decoration: none;
        color: $teal;
        white-space: nowrap;

        @media all and (max-width: $breakpoint-tablet) {
          color: $white;
          text-align: left;
          border-right: none;
          padding: 13px 20px;
        }

        &:hover {
          
        }
      }

      &:last-of-type {
        border-right: none;
      }

      &.active {
        .mega-menu-link {
          background-color: $lighterTeal;
          color: $teal;

          &:hover {
            color: $teal;
          }

          @media all and (max-width: $breakpoint-tablet) {
            padding: 0 2rem 0 4rem;
            &:after {
              content: "\f053";
              left: 2rem;
              right: auto;
              top: 0;
            }
          }
        }
      }

      .border-left {
        border-left: solid 1px $border-color;
        padding-left: 3rem;
        @media all and (max-width: $breakpoint-tablet) {
          border-left: none;
          padding-left: 0;
        }
      }

      .icon-card.no-border {
        margin-top: -15px;
        padding: 10px 0;
        @media all and (max-width: $breakpoint-tablet) {
          margin-top: 0;
          i {
            font-size: 1.5rem;
          }
        }
      }

      // styles for lists inside the mega menu dropdown
      .mega-menu-sub-link {
        padding: 0.4rem 0; /*to accommodate real-world links that wrap to multiple lines*/
        font-size: 0.8125rem;
        font-family: $font-family;
        text-transform: uppercase;
        line-height: 1.2em; /*to accommodate real-world links that wrap to multiple lines*/
        border: none;
        text-align: left;
        position: relative;

        &:after {
          position: absolute;
          content: "\0203A";
          margin-left: 0.25rem;
        }

        @media all and (max-width: $breakpoint-tablet) {
          font-size: 0.75rem; /*making mobile secondary links match the initial menu links */
          letter-spacing: 0.1rem;
          padding: $mobile-sub-link-padding-vertical
            $mobile-sub-link-padding-horizontal;
          &:after {
            content: "\f054";
            font-family: FontAwesome, serif;
            color: $mobile-arrow-color;
            font-size: $mobile-arrow-size;
            position: absolute;
            top: $mobile-sub-link-padding-vertical;
            right: 0;
          }
        }

        a,
        a:visited,
        a:active {
          color: $sub-link-color;
          font-weight: 600;
          @media all and (max-width: $breakpoint-tablet) {
            color: $mobile-sub-link-color;
          }

          &:hover {
            color: $sub-link-hover-color;
          }
        }
      }

      // mobile menu
      // styles for the back link inside the droplet (on level 2)
      .mega-menu-back-link {
        display: none;

        @media all and (max-width: $breakpoint-tablet) {
          display: block;
          position: relative;
          text-align: left;
          color: white;
          padding: 1rem;
          background: #373E41;
          font-family: "FFBauWebProRegular", sans-serif;
          &:after {
            font-family: FontAwesome, serif;
            color: $mobile-arrow-color;
            font-size: $mobile-arrow-size;
            position: absolute;
            content: "\2039";
            left: -2.25rem;
            right: auto;
            top: 0.25rem;
          }
        }
      }
    }
  }

  // mobile menu
  // hamburger menu
  #mobile-menu {
    display: none;
    z-index: 1000;
    //position: fixed;
    right: 1rem;
    top: 4.25rem;
    margin: 0 0 0 auto;
    width: 30px;
    height: 20px;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;

    @media all and (max-width: $breakpoint-tablet) {
      display: block;
    }

    &::after {
      display: none;
    }

    &.open {
      & + .mobile-menu {
        right: 0;
      }
    }

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: $hamburger-color;
      border-radius: 0;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
    }

    span:nth-child(1) {
      top: 0;
    }

    span:nth-child(2),
    span:nth-child(3) {
      top: 8px;
    }

    span:nth-child(4) {
      top: 16px;
    }

    &.open span:nth-child(1) {
      top: 18px;
      width: 0;
      left: 50%;
    }

    &.open span:nth-child(2) {
      transform: rotate(45deg);
    }

    &.open span:nth-child(3) {
      transform: rotate(-45deg);
    }

    &.open span:nth-child(4) {
      top: 18px;
      width: 0;
      left: 50%;
    }
  }

  // mobile menu
  // styles for the top and bottom static areas
  .mobile-menu-top {
    display: none;
    @media all and (max-width: $breakpoint-tablet) {
      display: block;
    }
  }

  .mobile-logo {
    padding: 0 0 1rem 1rem;
    margin-top: 49px;
  }

  // sticky nav styles
  &.sticky {
    background: $sticky-background;
    position: fixed;
    top: 0;

    #mega-menu {
      li {
        .mega-menu-link {
          color: $sticky-link-color;
        }
      }
    }
  }
}