.hr {
    
    &-icon-wrap {
        @extend %clearFix;
        margin: 40px 0;
        background: $darkTeal;
        color: #fff;
    }
    
    &-icon-group {
        text-align: center;
        padding-top: 15px;
        padding-bottom: 15px;
        
        &-last {
            @include breakpoint(tablet) {
                margin-left: 16.667%;
            }
        }
    }
    
    &-title {
        color: #fff;
    }
    
    &-icon {
        max-width: 80px;
        height: auto;
        margin: 10px auto;
    }
    
    &-videos {
        iframe {
            margin-bottom: 10px;
        }
    }
    
    &-why {
        &-icon {
            height: 80px;
            width: 80px;
            background: $darkTeal;
            border: 2px solid $lightTeal;
            border-radius: 50%;
            padding: 15px;
            position: relative;
            float: left;
            margin-right: 10px;
            
            img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                max-height: 60px;
                max-width: 60px;
                margin: auto;
                display: block;
            }
        }
        
        &-icons {
            margin-top: 30px;
            @include breakpoint(lt-tablet) {
                .col-6 {
                    clear: both;
                    margin: 20px 0;
                }
            }
            
            
            h4 {
                margin: 30px 0 0 20px;
                
            }
        }
    }
    
    &-numbered-list {
        margin: 0;
        padding: 0;
        
        li {
            margin: 10px 0;
            list-style: none !important;
            @extend %clearFix;
        }
        span {
            display: block;
            float: left;
            height: 40px;
            width: 40px;
            background: $darkTeal;
            border: 2px solid $lightTeal;
            border-radius: 50%;
            text-align: center;
            line-height: 40px;
            font-size: 24px;
            font-style: italic;
            font-weight: bold;
            color: #fff;
            margin-right: 10px;
        }
        h3 {
            margin: 0;
            padding-top: 10px;
        }
        &-content {
            float: right;
            width: calc(100% - 60px);
        }
    }
    
    &-hiring-process {
        margin: 0 auto;
        padding: 0;
        max-width: 480px;
        position: relative;
        
        &:before {
            content: '';
            position: absolute;
            left: 38px;
            top: 20px;
            bottom: 100px;
            width: 4px;
            background: #E5E8EC;
        }
        
        
        li {
            margin: 10px 0;
            list-style: none !important;
            @extend %clearFix;
        }
        a {
            font-weight: bold;
            text-decoration: none;
        }
        span {
            display: block;
            float: left;
            height: 80px;
            width: 80px;
            background: $darkTeal;
            border: 2px solid $lightTeal;
            border-radius: 50%;
            text-align: center;
            margin-right: 10px;
            padding: 15px;
            position: relative;
            
            img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                max-height: 60px;
                max-width: 60px;
                margin: auto;
                display: block;
            }
        }
        h3 {
            margin: 0;
            padding-top: 10px;
        }
        &-content {
            float: right;
            width: calc(100% - 100px);
        }
    }
    
    &-sidebar {
        margin-top: 20px;
        
        a.navicent-btn {
            background: $lightTeal;
            border: 2px solid $lightTeal;
            color: $darkTeal !important;
        }
        small {
            color: #fff;
            
            a {
                color: #fff;
                font-weight: bold;
            }
        }
    }
    
    &-departments,
    &-commerce {
        list-style: none;
        margin: 0 0 20px;
        padding: 0;
        
        li {
            margin: 0 0 20px;
            padding-left: 50px;
            background-image: url('../img/hr/icon-nursing-departments.svg');
            list-style: none !important;
            height: 40px;
            background-size: 40px 40px;
            background-repeat: no-repeat;
            line-height: 40px;
        }
    }
    
    &-commerce li {
        background-image: url('../img/hr/icon-commerce.svg');
    }
    
    &-tagline {
        font-size: 24px;
        text-align: center;
        
        span {
            font-style: italic;
            color: $red;
        }
    }
}

.two-column {
    @include breakpoint(tablet) {
        column-count: 2;
        column-gap: 40px;
    }
    
    a {
        text-decoration: none;
        font-weight: bold;
    }
}

.community-slider {
    li {
        padding: 20px 70px;
        text-align: center;
    }
    
    .flex-direction-nav {
        list-style: none;
        
        li {
            list-style: none;
            margin: 0;
            padding: 0;
            
            a {
                opacity: 1;
                top: 40px;
            }
        }
        
        .flex-next {
            right: 0;
            width: 50px;
            height: 50px;
            background-image: url('../img/hr/community-slider-right.png');
            text-indent: -999em;
            text-align: left;
            
            &:before {
                display: none;
            }
        }
        .flex-prev {
            left: 0;
            width: 50px;
            height: 50px;
            background-image: url('../img/hr/community-slider-left.png');
            text-indent: -999em;
            text-align: left;
            
            &:before {
                display: none;
            }
        }
    }
}