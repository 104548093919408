.address_block,
.map_box
{
	float: left;
	display: inline;
	max-width: 48%;
	margin: 1%;

	@include breakpoint(lt-tablet) {
		margin: 10px 0;
		max-width: 100%;
	}
}

.address_block p
{
	margin-top: 0;
}

a.gpop_link
{
	color: $darkTeal;
	display: block;
	font-size: smaller;
	line-height: 14px;
	text-decoration: none;
	margin-top: 3px;
}

.map_box
{
	float: right;
	height: 120px;
	border: solid 1px $darkCloud;
	width: 100%;

	@include breakpoint(lt-tablet) {
		display: none;
	}
}

.location_name
{
	color: $darkTeal;
	font-weight: bold;
	font-size: larger;
}

#branch_locations
{
	margin-top: 20px;
	overflow: hidden;
}

#map
{
	border: solid 1px $darkCloud;
}