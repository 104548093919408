.accordion {
    margin: 0;
    padding: 0;
    border: 2px solid #27818A;
    border-radius: 3px;
    width: 100%;
  }
  
  .accordion h3 {
    margin: 0;
    padding: 0;
  }
  
  .accordion:focus-within {
    border-color: #000;
  }
  
  .accordion:focus-within h3 {
    background-color: #EFF8F8; 
  }
  
  .accordion > * + * {
    border-top: 1px solid #27818A;
  }
  
  .accordion-trigger {
    background: none;
    color: #006970;
    display: block;
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    padding: 1em 1.5em;
    position: relative;
    text-align: left;
    width: 100%;
    outline: none;
  }
  
  .accordion-trigger:focus,
  .accordion-trigger:hover {
    background: #EFF8F8;
  }
  
  .accordion-trigger:focus {
    outline: 4px solid transparent;
  }
  
  .accordion > *:first-child .accordion-trigger,
  .accordion > *:first-child {
    border-radius: 5px 5px 0 0;
  }
  
  .accordion > *:last-child .accordion-trigger,
  .accordion > *:last-child {
    border-radius: 0 0 5px 5px;
  }
  
  button {
    border-style: none;
  }
  
  .accordion button::-moz-focus-inner {
    border: 0;
  }
  
  .accordion-title {
    display: block;
    pointer-events: none;
    border: transparent 2px solid;
    border-radius: 5px;
    padding: 0.25em;
    outline: none;
  }
  
  .accordion-trigger:focus .accordion-title {
    border-color: #27818A;
  }
  
  .accordion-icon {
    border: solid currentcolor;
    border-width: 0 2px 2px 0;
    height: 0.5rem;
    pointer-events: none;
    position: absolute;
    right: 2em;
    top: 50%;
    transform: translateY(-60%) rotate(45deg);
    width: 0.5rem;
  }
  
  .accordion-trigger:focus .accordion-icon,
  .accordion-trigger:hover .accordion-icon {
    border-color: #006970;
  }
  
  .accordion-trigger[aria-expanded="true"] .accordion-icon {
    transform: translateY(-50%) rotate(-135deg);
  }
  
  .accordion-panel {
    margin: 0;
    padding: 1em 1.5em;
    
    ul,li {
      border-bottom: none !important;
      list-style: disc !important;
    }
  }
  
  /* For Edge bug https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/4806035/ */
  .accordion-panel[hidden] {
    display: none;
  }

.box {
    margin: 20px 0;
    }
    
    ul {
        list-style: none;
    }

.top-level {
    display: inline-block;
    width: 100%;
    border-top: 1px solid rgb(197, 197, 197);
    line-height: 50px;
    margin: 5px 0;
    font-weight: 400;
    font-size: 16px;
    color: #006970;

    &:hover {
        cursor: pointer;
    }

    .header {
        display: inline-block;
        width: 95%;
        margin-top: 10px;
        padding-left: 20px;

        h3 {

            &:hover {
                color: $darkTeal;
            }
        }
    }

    &::after {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900; //do not remove this font weight or the icon will not work!
        content: '\f0dd';
    }
}

.second-level {
    background-color: white;
    color: $char;
    display: none;
    margin-top: -5px;
    box-sizing: border-box;
    line-height: 1.3em;
    margin: 0 20px 20px 20px;
}

.second-level ul,
.second-level li {
    list-style: none !important;
}

.active {
    
    span {
    width: 100%;
    display: inline-block;
    }
}