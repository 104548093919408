// Mixins

@mixin imageBlock($width, $height, $background, $inlineBlock: false) {
	width: $width;
	height: 0px;
	background: $background;
	margin: 0;
	padding: $height 0 0;
	display: block;
	overflow: hidden;
	outline: none !important;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow: inset $top $left $blur $color;
        -moz-box-shadow: inset $top $left $blur $color;
        box-shadow: inset $top $left $blur $color;
    } @else {
        -webkit-box-shadow: $top $left $blur $color;
        -moz-box-shadow: $top $left $blur $color;
        box-shadow: $top $left $blur $color;
    }
}

@mixin rounded($radius: 5px) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

@mixin transition($time: .2s) {
    -webkit-transition: all $time ease-in-out;
    -moz-transition: all $time ease-in-out;
    -ms-transition: all $time ease-in-out;
    -o-transition: all $time ease-in-out;
    transition: all $time ease-in-out;
}

//breakpoint mixin
@mixin breakpoint($point) {
	@if $point == lt-tablet {
		@media all and (max-width: 767px) { @content; }
	}
	@if $point == tablet {
		@media all and (min-width: 768px) { @content; }
	}
	@if $point == lt-desktop {
		@media all and (max-width: 979px) { @content; }
	}
	@else if $point == desktop {
		@media all and (min-width: 980px) { @content; }
	}
	@else if $point == widescreen {
		@media all and (min-width: 1200px)  { @content; }
	}
}

// Functions

@function em($target, $base: 16) {
	@return ($target / $base) * 1em;
}

// Common Extensions

%clearFix {
	zoom: 1;

	&:before,
	&:after {
		content: "";
		display: table;
	}

	&:after {
	    clear: both;
	}
}
.clearFix {
	zoom: 1;

	&:before,
	&:after {
		content: "";
		display: table;
	}

	&:after {
	    clear: both;
	}
}

@mixin underline($underline, $shadow) {
	background-image:
		linear-gradient($shadow, $shadow),
		linear-gradient($shadow, $shadow),
		linear-gradient($underline, $underline);	
	background-size:
		0.10em 0.01em,
		0.10em 0.07em,
		0.05em 0.05em;
	background-repeat:
		no-repeat,
		no-repeat,
		repeat-x;
	background-position-y:
		93%,
		93%,
		93%;
	background-position-x:
		5%,
		100%,
		5%;
	text-shadow:
		0.10em  0.00em $shadow,
		0.10em  0.02em $shadow,
		0.10em  0.04em $shadow,
		0.10em  0.06em $shadow,
		0.10em  0.08em $shadow,
		0.10em  0.10em $shadow,
		0.08em  0.10em $shadow,
		0.06em  0.10em $shadow,
		0.04em  0.10em $shadow,
		0.02em  0.10em $shadow,
		0.00em  0.10em $shadow,
		-0.02em  0.10em $shadow,
		-0.04em  0.10em $shadow,
		-0.06em  0.10em $shadow,
		-0.08em  0.10em $shadow,
		-0.10em  0.10em $shadow,
		-0.10em  0.08em $shadow,
		-0.10em  0.06em $shadow,
		-0.10em  0.04em $shadow,
		-0.10em  0.02em $shadow,
		-0.10em  0.00em $shadow,
		-0.10em  0.02em $shadow,
		-0.10em  0.04em $shadow,
		-0.10em -0.06em $shadow,
		-0.10em -0.08em $shadow,
		-0.10em -0.10em $shadow,
		-0.08em -0.10em $shadow,
		-0.06em -0.10em $shadow,
		-0.04em -0.10em $shadow,
		-0.02em -0.10em $shadow,
		0.00em -0.10em $shadow,
		0.02em -0.10em $shadow,
		0.04em -0.10em $shadow,
		0.06em -0.10em $shadow,
		0.08em -0.10em $shadow,
		0.10em -0.10em $shadow,
		0.10em -0.08em $shadow,
		0.10em -0.06em $shadow,
		0.10em -0.04em $shadow,
		0.10em -0.02em $shadow;
}