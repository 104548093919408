// Home
.hero {
	background: url(../ui/bg-hero.jpg);
	height: 408px;
	position: relative;
	max-width: 1000px !important;
	width: 100%;

	.play-video {
		position: relative;
    	display: block;
		
		&:after {
			content: '';
			display: block;
			width: 132px;
			height: 132px;
			background: url(../img/icon-play.png);
			text-indent: -9999px;
			position: absolute;
			margin: auto;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
		}
	}
	.tagline {
		position: absolute;
		top: 120px;
		left: 610px;
		width: 340px;
		font-family: $font-serif;
		font-style: italic;
		font-size: 30px;
		font-weight: normal;
		color: $blue;
		line-height: 1.2em;
	}
}
.slogan {
	text-align: center;
	margin-top: 2em;
	margin-bottom: 2em;

	h2 {
		font-family: $font-serif;
		font-size: 44px;
		font-weight: normal;
		color: #898b8e;
		text-align: center;
		margin: .7em auto;
		display: inline-block;
		@include underline($blue, #fff);

		span {
			color: $red;
		}
	}
}
.callouts {

	ul {
		margin: 0;
		@extend %clearFix;

		.callout {
			float: left;
			width: 24%;
			margin: 10px .5%;
			position: relative;
			background: $green;
			padding: 10px;
			min-height: 184px;
			background-position: 95% 95%;
			background-repeat: no-repeat;

			@include breakpoint(lt-desktop) {
				min-height: 215px;
			}

			@include breakpoint(lt-tablet) {
				width: 100%;
				margin: 1.5% 0;
				min-height: 185px;

				.title {
					font-size: 22px;

				}

				.summary {
					font-size: 16px;
				}
			}

			.title{
				font-size: 20px;
				line-height: 1em;
				font-family: $font-sans;
				font-weight: bold;
			}
			.summary {
				font-weight: 300;
				line-height: 1.2em;
				font-size: 14px;
				margin: .5em 0;
			}
			.learn-more {
				display: inline-block;
				margin-top: .25em;
				background: #fff;
				font-weight: 300;
				font-size: 18px;
				text-align: center;
				padding: 3px 10px;
				color: #404040;
				text-decoration: none;
				position: absolute;
				bottom: 20px;
				left: 10px;

				@include box-shadow(-5px,-5px,5px,rgba(0,0,0,.07));
			}

			&.specialties {
				background-image: url(../ui/callout-specialties.png);
			}
			&.top-two {
				background-image: url(../ui/callout-top-two.png);
			}
			&.one-you {
				-webkit-border-radius: 0 0 0 50%;
				border-radius: 0 0 0 50%;
				background-image: url(../ui/callout-one-you.png);

				.title {
					font-size: 36px;
					color: #4d4d4f;
					text-align: center;
				}
				background-position: 10px 85%;
			}
			&.team-members {
				background-image: url(../ui/callout-team-members.png);
			}
			&.buildings {
				background-image: url(../ui/callout-buildings.png);
			}
		}
	}

	&.childrens-hospital {
		.callout {
			background-color: #eee;
			min-height: 210px;
			list-style: none;

			@include breakpoint(tablet) {
				width: 48%;
				margin: 10px 1%;
			}

			li {
				margin-left: 15px;
			}

			&.balloons {
				background-image: url('../img/callout-balloons.png');
			}

			&.person {
				background-image: url('../img/callout-person.png');
			}

			&.heart {
				background-image: url('../img/callout-heart.png');
			}

			&.bear {
				background-image: url('../img/callout-bear.png');
			}
		}
	}
}
.home-blurb {
	margin: 1em 0;
	text-align: center;
	font-size: 18px;
	line-height: 2em;
	font-family: $font-serif;

	.wrap {
		text-align: center;
	}

	@include breakpoint(lt-desktop) {
		font-size: 16px;
	}

	@include breakpoint(lt-tablet) {
		br {
			display: none;
		}
	}
}
.awards {
	margin: 2em 0 4em;
	@extend %clearFix;

	.award {
		width: 33.33333%;
		float: left;
		padding: 1em 2em;
		font-size: 18px;

		img {
			display: block;
			margin: 0 auto 2em;
		}
		p {
			margin-bottom: 0;
		}
		&:nth-child(2) {
			border-right: 3px solid #c4c5c6;
			border-left: 3px solid #c4c5c6;
		}
	}
}
.wrap-wrap {
	text-align: center;
}
.service-icons-wrap {
	background: $blue;
	text-align: center !important;
	@extend %clearFix;
	margin-top: 50px;

	.wrap {
		text-align: center;
	}

	.header {
		margin: 3em auto;
	}

	h2 {
		font-family: $font-serif;
		font-size: 44px;
		font-weight: normal;
		color: #fff;
		margin: .7em auto;
		display: inline-block;
		@include underline(#fff, $blue);
		text-align: center;
		width: 580px;
	}
	.service-icons {
		margin: 0;

		li {
			float: left;
			// width: 14%;
			// margin-right: 3%;
			// margin-left: 3%;
			width: 20%;
    		float: left;
    		padding: 0 10px;
    		
			img {
				width: auto;
				max-width: 100%;
			}

			@include breakpoint(lt-tablet) {
				width: 46%;
				margin-right: 2%;
				margin-left: 2%;
				margin-bottom: 2%;

				a {
					min-height: 190px;
					padding-left: 0;
					padding-right: 0;
				}
			}

			// @include breakpoint(tablet) {
			// 	&:nth-child(11) {
			// 		margin-left: 20%;
			// 	}
			// }

			a {
				text-align: center;
				display: block;
				min-height: 175px;
				width: 100%;
				margin: 0 auto;
				background-position: center top;
				background-repeat: no-repeat;
				padding: 120px 10px 0 10px;
				color: $green;
				text-decoration: none;
				font-size: 13px;
				line-height: 1.2em;
				background-size: initial;
				background-image: url(../ui/service-general-healthcare.png);
			}
			&.WomensServices {
				// @include breakpoint(tablet) {
				// 	margin-left: 12.5%;
				// }
			}
			&.WomensServices a {
				background-image: url(../ui/services-womens-health.png);
			}
			&.AffiliatedServices a,
			&.AffiliatedServicesandHospitals a {
				background-image: url(../ui/services-affiliated.png);
			}
			&.PreventionRehabilitation a {
				background-image: url(../ui/services-rehab.png);
			}
			&.AdultMedicineSpecialtyCenters a {
				background-image: url(../ui/services-adult-new.png);
			}
			&.Diagnostics a {
				background-image: url(../ui/services-diagnostics.png);
			}
			&.ChildrensServices a {
				background-image: url(../ui/services-children.png);
			}
			&.OutpatientUrgentCare a {
				background-image: url(../ui/services-outpatient.png);
			}
			&.PostAcuteCare a {
				background-image: url(../ui/services-post-acute.png);
			}
			&.PhysicianPractices a,
			&.NavicentHealthPhysicianGroup a {
				background-image: url(../ui/services-physician-practices.png);
			}
			&.Cancer a {
				background-image: url('../ui/services-adult.png');
			}
			&.GeneralServices a {
				background-image: url(../ui/service-navicent-general.png);
			}
			&.RegionalPartners a {
				background-image: url('../ui/service-regional-partners.png');
			}
			// &.Cancer {
			// 	@include breakpoint(tablet) {
			// 		margin-left: 12.5%;
			// 	}
			// }
			&.Heart a {
				background-image: url('../ui/services-heart.png');
			}
			&.PrimaryCare a {
				background-image: url('../ui/services-primary-care.png');
			}
		}
	}
	&.white {
		background: #fff;

		h2 {
			@include underline($blue, #fff);
			color: $blue;
		}
		.service-icons {
			li {
				a {
					color: $blue;
				}
			}
		}

		@include breakpoint(lt-tablet) {
			margin-top: 0;
		}
	}
}
.center.nev {
	li {
		line-height: 30px;
	}
}
.affordable-care {
	background: url(../ui/bg-affordable-care.jpg) no-repeat center center;
	padding: 0 100px;

	@include breakpoint(lt-desktop) {
		background-size: contain;
		padding: 0 10%;
		min-height: 300px;
	}

	@include breakpoint(lt-tablet) {
		min-height: 275px;
		background-image: none;
		background-color: #fcfcfc;
	}

	@include breakpoint(desktop) {
		min-height: 358px;
	}

	h2 {
		margin-top: 100px;
		font-size: 28px;
		color: $blue;
		font-weight: normal;
		max-width: 650px;

		@include breakpoint(lt-desktop) {
			margin-top: 10%;
		}

		span {
			color: $red;
			font-style: italic;
		}
	}
	.affordable-care-nav {
		margin: 60px 0;
		@extend %clearFix;

		@include breakpoint(lt-desktop) {
			margin-top: 5%;
		}

		li {
			list-style: none;
			float: left;
			margin-right: 20px;

			a {
				color: #898b8e;
				font-size: 20px;
			}
		}
	}
}

/* Public Alert */
#public_alert {
	padding: 0;
	background-color: $darkTeal;
	margin: 0 auto;
	margin-bottom: 29px;
	max-width: 843px;
}
#public_alert h2, #public_alert h3 {
	color: white;
}
#public_alert h2 {
	background: $red;
	height: 50px;
	line-height: 50px;
	margin: 0;

	@include breakpoint(lt-tablet) {
		height: auto;
	}
}
#public_alert h3 {
	padding: 20px;
}

#closeCovid {
    color: #000;
}

//Guided Nav

.guided-nav {
	position: relative;
	margin-bottom: -110px;
	margin-top: -55px;
	
	@media (max-width: 500px) {
		margin-bottom: 0px;
		margin-top: 0px;
		background-color: $darkTeal;
	}
	
	#second-box {
		@media (max-width: 767px) {
			border-radius: 0;
			margin: 10px 0 15px 0;
		}
	}

	.box-teal {
		background-color: #007078;
		border-radius: 5px 0 0 5px;
		height: 150px;
		padding: 8%;
		display: flex;
		align-items: center;
		
		@media (min-width: 768px) {
			min-height: 150px;
			height: 100%;
		}
		
		form {
			background: none;
			border: none;
			padding: 0;
			width: 100%;
			
			label {
				font-family: "FFBauWebProBold", sans-serif;
				color: #fff;
				font-size: 20px;
				margin-bottom: 10px;
				display: block;
			}
			
			select {
				width: 100%;
				background-color: #fff;
			}
		}
		
		&-light {
			background-color: $lighterTeal;
			height: 150px;
			padding: 8%;
			display: flex;
			//align-items: center;
			flex-direction: column;
			-webkit-transition: all .3s ease-in-out;
			-moz-transition:    all .3s ease-in-out;
			-o-transition:      all .3s ease-in-out;
			-ms-transition:     all .3s ease-in-out;
			transition:         all .3s ease-in-out;
			
			@media (min-width: 768px) {
				height: 100%;
			}


			
			&:hover {
				background-color: $white;
				
			}

			.is-teal {
				font-family: "FFBauWebProBold", sans-serif;
				color: $darkTeal;
				font-size: 18px;
				//font-weight: bold;
			}
			
			p {
				margin: 0 0 5px 0;
				line-height: 1.2;
			}
			
			a {
				text-decoration: underline;
			}
			
			&-icon {
				background-image: url('../ui/icon-logo.svg');
				background-position: center center;
				background-repeat: no-repeat;
				border-radius: 0 5px 5px 0;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				
				a {
					font-family: "FFBauWebProBold", sans-serif;
					font-size: 22px;
					//font-weight: bold;
					text-decoration: none;
				}
			}
		}
	}
	
	.home-navigation-box {
		align-self: start;
		width: 100%;
		height: 80%;
	}
	
}


