$layout: (
    width: 1168px,
    gutters: 30px,
    columns: 12
);

@font-face {
    font-family: 'FFBauWebProRegular';
    src: url(../fonts/BauPro.otf);
  }

@font-face {
    font-family: 'FFBauWebProBold';
    src: url(../fonts/BauPro-Bold.otf);
}

@font-face {
    font-family: 'FFBauWebProBoldItalic';
    src: url(../fonts/BauPro-BoldItalic.otf);
}

@font-face {
    font-family: 'FFBauWebProRegularItalic';
    src: url(../fonts/BauPro-Italic.otf);
}

@font-face {
    font-family: 'FFBauWebProMedium';
    src: url(../fonts/BauPro-Medium.otf);
}

@font-face {
    font-family: 'FFBauWebProMediumItalic';
    src: url(../fonts/BauPro-MediumItalic.otf);
}

@font-face {
    font-family: 'FFBauWebProSuper';
    src: url(../fonts/BauPro-Super.otf);
}

@font-face {
    font-family: 'FFBauWebProSuperItalic';
    src: url(../fonts/BauPro-SuperItalic.otf);
}

// Fonts
$font-sans: 	    'Fira Sans', sans-serif;
$font-serif: 	    'Libre Baskerville', serif;
$bau:               'FFBauWebProRegular', sans-serif;
$bauBold:           'FFBauWebProBold', sans-serif;
$bauBoldItalic:     'FFBauWebProBoldItalic', sans-serif;
$bauItalic:         'FFBauWebProRegularItalic', sans-serif;
$bauMed:            'FFBauWebProMedium', sans-serif;
$bauMedItalic:      'FFBauWebProMediumItalic', sans-serif;
$bauSuper:          'FFBauWebProSuper', sans-serif;
$bauSuperItalic:    'FFBauWebProSuperItalic', sans-serif;

// Colors
$blue: 			#004a98;
$green:			#00bc70;
$yellow:        #f0c300;
$orange:        #ff7f31;
$red:			#D41200;

$teal:          #00818a;
$darkTeal:      #007078;
$lightTeal:     #b2d9dc;
$lighterTeal:   #e4f0f2;

$char:          #373e41;
$darkChar:      #1f2325;
$lightChar:     #515a5e;
$lighterChar:   #656f75;

$cloud:         #c6d2d7;
$darkCloud:     #acbabf;
$lightCloud:    #e2ebee;
$lighterCloud:  #f4f8f9;

$white:         #ffffff;

//Old Color Variables
$darkblue: #2d436a;
$lightgreen: #51bb8b;

$navgreenlight: #95f9e3;
$navgreendark: #48bb8d;
$navbluedark: #274469;
$navred: #c11c21;