.sidebar {
	background: $teal;
	padding: 10px;
	margin-bottom: 30px;
	
	@include breakpoint(lt-tablet) {
		margin-top: 20px;
		margin-bottom: 0;
	}

	ul {
		margin: 0;

		li {
			list-style: none;

			a {
				color: #fff;
				font-size: 14px;
				padding: 5px 0;
				line-height: 1.2em;
				display: block;
				font-weight: bold;
				text-transform: uppercase;
				border-bottom: 1px solid rgba($lightTeal, .2);
			}

			ul {
				margin-left: 10px;
				margin-bottom: 10px;

				li {

					a {
						font-size: 14px;
						text-transform: none;
						border-bottom: none;
					}
					
					&:last-child {
						// a {
						// 	border-bottom: none;
						// }
					}
				}
			}
		}
	}
	h1,h2,h3,h4,h5,p {
		color: #fff;
		@include breakpoint(lt-tablet){
    	color:initial;
  		}
	}
	p {
		font-size: 14px;
		font-weight: normal;
		
	}
	.center {
		text-align: center;
		margin-right: auto;
		margin-left: auto;
		display: block;
	}
}