// Wrap, Grid
html {
  	box-sizing: border-box;
}
*, *:before, *:after {
  	box-sizing: border-box;
}

html,
body {
	max-width: 100%;
	overflow-x: hidden;
	font-family: $bau;
}

body {
	width: 100%;
	
	&:after {
		opacity: 0;
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0,0,0,.7);	
		z-index: 30;
		transition: .3s ease-in-out all;
		pointer-events: none;
	}
	
	&.search-open {
		overflow-y: hidden;
		
		&:after {
			opacity: 1;
		}
	}
}

.row {
	@extend %clearFix;
}

#page {
	width: 100%;
}

*, *:before, *:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
 }
.wrap {
	max-width: 1000px;
	margin: 0 auto;
	padding: 0 20px;
	@extend %clearFix;
	text-align: left;

	&.full-width {
		padding: 0;
	}
}

.hide-mobile {
	@media (max-width: 767px) {
		display: none;
	}
}


//Flex Layout

.flex {
    display: flex !important;
    margin: -(map-get($layout, gutters) / 2);

    & + & {
        margin-top: map-get($layout, gutters) / 2;
    }

    &-no-gutters {
        margin: 0;
    }

    &-double-gutters {
        margin: -(map-get($layout, gutters));

        & + & {
            margin-top: map-get($layout, gutters);
        }
    }

    &-wrap {
        flex-flow: row wrap;
    }

    &-wrap-auto {
        flex-flow: row wrap;
    }

    &-direction-reverse {
        flex-direction: row-reverse;
    }

    &-direction-column {
        flex-direction: column;
    }

    &-direction-column-reverse {
        flex-direction: column-reverse;
    }

    &-space-between {
        justify-content: space-between;
    }

    &-space-around {
        justify-content: space-around;
    }

    &-align-center {
        align-items: center;
    }

    &-align-stretch {
        align-items: stretch;
    }

    &-align-start {
        align-items: flex-start;
    }

    &-align-end {
        align-items: flex-end;
    }

    &-justify-center {
        justify-content: center;
    }

    &-justify-start {
        justify-content: flex-start;
    }

    &-justify-end {
        justify-content: flex-end;
    }

    &-col {
        flex: 1 1 auto;
        flex-basis: 80px;
        padding: (map-get($layout, gutters) / 2);

        .flex-no-gutters > & {
            padding: 0;
        }

        .flex-double-gutters > & {
            padding: map-get($layout, gutters);
        }
        
        .flex-wrap > & {
            @media (max-width: 767px) {
                flex: 1 1 100%;
            }
        }

        &-auto {
            flex: 0 0 auto;
        }

        &.flex-align-center {
            align-self: center;
        }

        &.flex-align-stretch {
            align-self: stretch;
        }
    
        &.flex-align-start {
            align-self: flex-start;
        }
    
        &.flex-align-end {
            align-self: flex-end;
        }

        @media (max-width: 767px) {
            &.flex-first-mobile {
                order: -1;
            }
        }

        @media (min-width: 460px) {
            @for $i from 1 through map-get($layout, columns) {
                &-mobile-#{$i} {
                    flex: 1 1 (100% / (map-get($layout, columns) / $i));
                    max-width: 100% / (map-get($layout, columns) / $i);
                }
            }
        }

        @media (min-width: 768px) {
            @for $i from 1 through map-get($layout, columns) {
                &-#{$i} {
                    flex: 1 1 (100% / (map-get($layout, columns) / $i));
                    max-width: 100% / (map-get($layout, columns) / $i);
                }
            }
        }
        
        &-menu {
            .flex-wrap > & {
                @media (max-width: 1040px) {
                    flex: 1 1 100%;
                }
            }

            @media (min-width: 1040px) {
                @for $i from 1 through map-get($layout, columns) {
                    &-#{$i} {
                        flex: 1 1 (100% / (map-get($layout, columns) / $i));
                        max-width: 100% / (map-get($layout, columns) / $i);
                    }
                }
            }
        }
    }

    @media (max-width: 767px) {
        &-mobile-block {
            display: block;
        }

        &-column-mobile {
            flex-direction: column;
        }

        &-mobile-full-width {
            width: 100%;
        }
    }
}


// NEW
// Heading
.privacy-notification {
	background: $navred;
	text-align: center;
	color: #fff;
	padding: 5px 0;
	
	a {
		color: #fff;
		text-decoration: underline;
	}
}

.top-header {
	// background-color: $teal;
	background-color: $teal;

	&-links {
		padding: 15px;
		
		@media (max-width: 767px) {
			padding: 10px 0 0 0;
		}
	}
	
	&-icon {
		max-width: 20px;
		float: left;
		margin-right: 5px;
		overflow: hidden;
		
		svg {
			fill: $white;
			width: 20px;
			height: 20px;
		}
	}
	
	span {
		color: $white;
		text-transform: uppercase;
		clear: both;
	}

	.text-right {
		text-align: right;
	}

	

	.flex-parent {
		display: flex; 
		flex-flow: row wrap;
		background-color: $teal;
		width: 100vw;
		
		justify-content: center;

		div {
			padding: 5%;	
			min-width: 250px;
		}

		&-top {
			display: flex; 
			padding-left: 2vh;
			padding-right: 2vh;
			flex-flow: row nowrap;
			background-color: $teal;
			width: 100vw;
			justify-content: center;
	
			div {
				min-width: 250px;
				padding: 25px;


				h1 {
					margin: 0px;
					padding: 0px;
					font-weight: normal;
				}

				h2 {
					margin: 0px;
					padding: 0px;
					font-weight: normal;
				}
	
			}
		}
	}
	
	.navicent-search-input {
		display: none;
		position: relative;
		background-color: rgba(0, 0, 0, 0.9);
		color: $lightTeal;
		height: 100vh;
		width: 100vw;
		margin: auto;
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

		.title {
			background-color: $teal;
			text-align: center;
			padding: 20px;
			font-size: 2rem;
		}

		.close-btn {
			position: absolute;
			top: 2vh;
			color: #001e20 !important;
			right: 6vw;

			a {
				color: #001e20;
			}
			
			
		}


		h1 {
			text-align: center;
			margin-bottom: 0px;
			padding: 25px;
		}

		h2 {
			font-family: "FFBauWebProRegular", sans-serif;
		}
		
		
		&.open {
			display: block;
			position: fixed;
			left: 0px;
			top: 0px;
			z-index: 3000;
			margin: auto;
		}
		form {
			border-radius: 0px;
			padding: 20px 10px 10px 10px;
			width: 100vw;
			max-width: 100%;
		}
		input[type="text"] {
			height: 50px;
			border-radius: 0px;
			width: 80%;
			margin-bottom: 10px;
		}

		input[type="submit"] {
			display: inline-block;
			width: 15%;
			position: relative;
			border: none;
			padding: 10px 20px;
			min-width: 80px;
			-webkit-appearance: none;
		}

	
		
		// @include breakpoint(desktop) {
		// 	input[type="text"] {
		// 		height: 100px;
		// 		width: calc(100vw - 100px);
		// 		max-width: calc(100vw - 100px);
		// 		font-size: 30px;
		// 		padding-left: 70px;
		// 	}
		// 	input[type="submit"] {
		// 		width: 0;
		// 		height: 0;
		// 		position: absolute;
		// 		top: 0;
		// 		border: none;
		// 		-webkit-appearance: none;
		// 	}
		// }
	}
}


.navicent-header {
	@extend %clearFix;
	border-bottom: 1px solid rgba($darkTeal, .2);
	box-shadow: 0 5px 15px rgba(0,0,0,.15);
	position: relative;
	z-index: 99;
	padding: 25px 0;
	
	.navicent-logo {
		float: left;
		max-width: 200px;
		margin-right: 150px;
		position: relative;
		z-index: 9;
		@media screen and (max-width: 768px) {
			margin-right: 0;
		}
		
		img {
			display: block;
			max-width: 100%;
			max-height: 100%;
			margin: auto;
		}
	}
	
	.navicent-navigation {
		position: fixed;
		width: 100vw;
		left: -100vw;
		top: 0;
		height: 100vh;
		overflow-y: scroll;
		transition: .3s ease-in-out all;
		background: rgba($darkTeal, .9);
		padding-top: 40px;
		
		@include breakpoint(lt-tablet) {
			z-index: 10;
		}
		
		.mega {
			white-space: normal;
			width: 100%;
			align-items: flex-start;
			
			&-nav {
				
			}
			
			&-info {
				
			}
		}
		
		&.open {
			left: 0;
		}
		
		ul,
		li {
			margin: 0;
			padding: 0;
		}
		
		li {
			flex: 1 1 auto;
			white-space: nowrap;
		}
		
		.subNavMenu a {
			padding-left: 20px;	
		}
		
		&-item {
			position: relative;
			
			a {
				color: #fff;
				font-size: 14px;
				display: block;
				height: 40px;
				line-height: 40px;
				padding: 0 20px;
				border-bottom: 1px solid $darkTeal;
				border-top: 1px solid rgba(255,255,255,.2);
				@media screen and (max-width: 1344px) {
					font-size: 14px;
				}

				@media screen and (max-width: 1237px) {
					font-size: 12px;
				}

				@media screen and (max-width: 1199px) {
					font-size: 14px;
				}
			}
			.more {
				position: absolute;
				display: block;
				z-index: 99;
				top: 0;
				right: 0;
				background: rgba(255,255,255,.7);
				width: 40px;
				height: 40px;
				color: $darkTeal;
				line-height: 40px;
				font-size: 32px;
				text-align: center;
				
				span {
					display: block;
					transition: .3s ease-in-out all;
				}
				
				&.open {
					span {
						transform: rotate(45deg);
					}
				}
			}
			
			&-sub-menu {
				display: none;
				
				a {
					//background: rgba(255,255,255,.2);
					height: 30px;
					line-height: 30px;
					font-weight: normal !important;
					font-size: 14px;
				}
			}
		}
		
		&-toggle {
			display: block;
			float: right;
			width: 40px;
			height: 40px;
			background: $darkTeal;
			position: relative;
			z-index: 99;
			transition: .3s ease-in-out all;
			
			svg {
				margin: 5px;
				
				path {
					fill: #fff;
				}
			}
			
			.toggle-line {
				transition: .3s ease-in-out all;
			}
			
			&.open {
				background: #fff;
				top: 0;
				position: fixed;
				right: 0;
				
				svg {
					path {
						fill: $darkTeal;
					}
				}
				
				.toggle-line-top {
					transform-origin: left;
					transform: rotate(45deg) translateX(-10px) translateY(-10px);
				}
				.toggle-line-middle {
					transform: transformX(40px);
					opacity: 0;
				}
				.toggle-line-bottom {
					transform-origin: left;
					transform: rotate(-45deg) translateX(-20px) translateY(20px);
				}
			}
		}
	}
	
	.navicent-search {
		&-wrap {
			float: right;
			width: 40px;
			height: 40px;
			background: $teal;
			z-index: 100;
		}
		&-toggle {
			display: block;
			width: 40px;
			height: 40px;
			
			&-icon {
				margin: 0 auto;
			}
			
			svg {
				margin: 10px;
				max-width: 40px;
				
				path {
					fill: #fff;
				}
			}
			
			span {
				display: none;
				font-weight: bold;
				font-size: 14px;
				text-transform: uppercase;
				text-align: center;
			}
		}
		&-input {
			display: none;
			
			input[type="text"] {
				max-width: 0px;
				transition: .3s ease-in-out all;
			}
		}
		
		&-wrap.open {
			position: relative;
			
		}
		
		@include breakpoint(desktop) {
			&-wrap {
				width: 100px;
				height: 100px;
			}
			&-toggle {
				width: 100px;
				height: 100px;
				
				&-icon {
					width: 60px;
					height: 60px;
					margin: 20px auto 0;
				}
				span {
					display: block;
				}	
			}
		}
	}
	
	@include breakpoint(desktop) {
		.navicent-navigation {
			display: block;
			clear: both;
			width: calc(100% - 100px);
			padding: 0;
			left: auto;
			background: none;
			height: auto;
			position: relative;
			overflow-y: visible;
			border-top: 1px solid $lightTeal;
			
			& > ul {
				display: flex;
				justify-content: space-around;
			}
			
			&-item {
				position: relative;
				display: inline-block;
				
				& > a {
					display: block;
					text-transform: uppercase;
					color: $darkTeal;
					border: none;
				}
				
				.more {
					display: none;
				}

				li:nth-child(even) {
					background: $lightCloud;
				}
				
				&-sub-menu {
					display: none;
					position: absolute;
					z-index: 999;
					top: calc(100% - 10px);
					left: 0;
					background: #fff;
					//box-shadow: 0 5px 7px rgba(0,0,0,.3);
					border: 1px solid $cloud;
					//border-radius: 7px;
					
					li {
						width: 300px;
					}

					
					a {
						display: block;
						width: 100%;
						padding: 3px 7px;
						color: #000;
						line-height: auto;
						height: auto;
						border-bottom: 1px solid rgba($lightTeal, .5);
						transition: .3s ease-in-out all;
						
						&:hover {
							background: $lightTeal;
						}
					}
				}
				
				&:hover {
					.navicent-navigation-item-sub-menu {
						display: block;
					}
				}
			}
			
			&-toggle {
				display: none;
			}
		}
	}
	
	@include breakpoint(widescreen) {
		.navicent-navigation {
			clear: none;
			//margin-top: 35px;
			border-top: none;
		}
	}
}


@media all and (min-width: 1600px) {
	// .navicent-header {
	// 	// display: none;
		
	// 	.navicent-navigation {
	// 		display: block;
	// 		clear: none;
	// 		width: calc(100% - 140px);
			
	// 		&-item {
	// 			& > a {
	// 				height: 120px;
	// 				line-height: 120px;
	// 			}
				
	// 			&-sub-menu {
	// 				top: calc(100% - 30px);
	// 			}
	// 		}
	// 	}
	// }
	// .navicent-search {
	// 	 &-wrap {
	// 	 	width: 120px;
	// 	 	height: 120px;
	// 	 }
		 
	// 	 &-toggle {
	// 	 	width: 120px;
	// 	 	height: 120px;
		 	
	// 	 	&-icon {
	// 			width: 80px;
	// 			height: 80px;
	// 			margin-top: 10px;
	// 		}
	// 		span {
	// 			display: block;
	// 			text-align: center;
	// 		}
	// 	 }
	// }
}

.navicent-hero {
	position: relative;
	padding-top: 140px;
	padding-bottom: 40px;
	background-image: url('../ui/nav-hero-mobile.jpg');
	background-position: right center;
	background-size: cover;
	@media screen and (max-width: 768px) {
		background-position: 801px center !important;
	}

	@media screen and (max-width: 508px) {
		background-position: 676px center !important;
		height: 900px;
	}
	
	.container {
		position: relative;
		z-index: 2;
	}
	
	.form-wrap {
		padding-left: 20px;
    	padding-right: 20px;
	    
	    @include breakpoint(tablet) {
	    	width: 40.33333%;
    		float: left;
	    }
	}
	
	&-panel-container {
		position: relative;
		height: 180px;
	}
	
	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba($darkTeal, .3);
	}
	
	
	h2 {
		text-align: center;
		color: #fff;
		text-shadow: 0 2px 10px #00000080;

		@media (max-width: 500px) {
			margin-bottom: 20px;
		}
		
		em {
			color: $navred;
		}
	}
	
	&-panel {
		opacity: 0;
		padding: 10px;
		background: #fff;
		border: 2px solid $lightTeal;
		border-radius: 9px;
		transition: .3s ease-in-out all;
		overflow: hidden;
		transform: translate(-300px, 0);
		position: absolute;
		bottom: 60px;
		
		&.active {
			opacity: 1;
			transform: translate(0,0);
		}
		
		&-toggle {
			display: inline-block;
			position: relative;
			padding: 12px 20px 10px 20px;
			margin-top: 10px;
			background: $lighterChar;
			//border: 2px solid $lightTeal;
			color: #fff !important;
			//font-weight: bold;
			border-radius: 5px;
			transition: .3s ease-in-out all;
			
			&-container {
				position: absolute;
				bottom: 0;
			}
			
			&:after {
				bottom: 100%;
				left: 50%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(136, 183, 213, 0);
				border-bottom-color: $lightTeal;
				border-width: 10px;
				margin-left: -10px;
				opacity: 0;
				transition: .3s ease-in-out all;
			}
			
			&.active {
				background: $darkTeal;
				
				&:after {
					opacity: 1;
				}
			}
			
			&:hover {
				background: $darkTeal;
				
				&:after {
					opacity: 1;
				}
			}
		}
	}
	
	.navicent-form-hero {
		@extend %clearFix;
		
		label {
			display: none;
		}
		
		select {
			width: 100%;
		}
		
		&-search {
			input {
				display: block;
				float: left;
			}
			input[type="text"] {
				width: calc(100% - 80px);
				border-radius: 9px 0 0 9px;
			}
			input[type="submit"] {
				width: 80px;
				border-radius: 0 9px 9px 0;
			}
		}
	}
	
	@include breakpoint(desktop) {
		padding: 80px 0;
		background-image: url('../ui/nav-hero.jpg');
		
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: rgba($darkTeal, .1);
		}
	}

	@media screen and (max-width: 768px) {
		padding-bottom: 300px !important;
		background-position: 686px center;
	}
}

.form-wrap-2 {
	@media screen and (max-width: 768px) {
		height: 89px;
	}
}

.form-wrap-mobile {
	@media screen and (max-width: 768px) {
		margin-bottom: 126px !important
	}
}

.navicent-quick-nav {
	background: $darkTeal;
	
	ul {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: space-around;
	}

	ul,
	li {
		margin: 0;
		padding: 0;
		font-size: 0;
		verticle-align: middle;
	}
	
	.navigation-highlight-item {
		flex-grow: 1;
		min-width: 50%;
		text-align: left;
		
		a {
			display: block;
			padding: 10px 0;
			font-size: 14px;
			color: #fff;
			font-weight: bold;
		}
		
		&.patient-portal-item {
			@include breakpoint(tablet) {
				.navigation-highlight-item-icon {
					position: relative;
					left: -5px;
				}
			}
		}
		
		&-icon {
			position: relative;
			top: 4px;
			margin: 5px auto;
			width: 20px;
			height: 20px;
			display: inline-block;
			
			svg {
				max-width: 20px;
				max-height: 20px;
				
				path,
				polygon {
					fill: $lightTeal;
					stroke: $lightTeal;
				}
			}
			
			&.no-stroke {
				svg {
					stroke: none;
				}
			}
		}
		
		span {
			display: inline-block;
		}
		
		@include breakpoint(desktop) {
			min-width: 20%;
			
			&-icon {
				display: block;
				width: 100%;
				height: 36px;
				text-align: center;
				
				svg {
					max-width: 36px;
					max-height: 36px;
				}
				
				&.express {
					svg {
						max-width: 60px;
						top: 7px;
					}
				}
			}
			
			span {
				display: block;
				text-align: center;
				text-transform: uppercase;
			}
		}
	}
}

.navicent-featured {
	&-image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: top center;
	}
	
	&-tabs {
		display: none;
		margin: 0;
		padding: 0;
	}
	&-tab {
		position: relative;
		margin: 0;
		padding: 0;
		border-bottom: 1px solid $darkTeal;
		display: block;
		padding: 10px;
		color: #fff;
		
		&:before {
			position: absolute;
			content: '';
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: $darkTeal;
			mix-blend-mode: multiply;
		}
			
		a {
			display: block;
			position: relative;
			padding: 10px;
			color: #fff;
		}
		&.flex-active,
		&:hover {
			border: 2px solid rgba($lightTeal, .5);
			
			&:before {
				mix-blend-mode: normal;
			}
		}
	}
	&-single {
		display: none;
		
		&.active {
			display: block;
		}
	}
	
	&-caption {
		background: rgba($white, 0.9);

		// @media (max-width: 767px) {
		// 	padding: 10px !important;
		// 	top: 10% !important;
		// 	left: 10% !important;
		// 	max-width: 80% !important;
		// 	text-align: center;
		// }
	}

	&-title {
		font-size: 1.75em;
		color: $teal;
		text-shadow: 0 2px 2px #FFFFFF80;

		@media (max-width: 767px) {
				font-size: 1rem;
		}
	}

	&-preview {
		p {
		color: $char;
		//text-shadow: 0 0 6px rgba(0,0,0,0.8);
		}
	}

	&-controls {
		.flex-control-nav {
			width: auto;
			bottom: auto;
			text-align: left;
			
			li {
				margin: 0;
				display: block;
			}
		}
	}
	&-content {
		.flexslider {
			display: flex;
			position: relative;
			margin: 0 auto;
			height: 500px;
			max-height: 500px;
			overflow: hidden;
			
			.home-hero-caption {
				position: absolute;
				//align-items: center;
				justify-content: flex-end;
				display: flex;
				width: 100%;
				height: 100%;
				padding: 50px 100px 50px 50px;
				
				@media (max-width: 767px) {
					align-items: flex-end;
				}
				
				&-text {
					color: #e4f0f2;
					font-size: 45px;
					text-shadow: 3px 3px 2px #000;
					//text-shadow: 2px 2px #007078;
					
					@media (max-width: 767px) {
						font-size: 40px;
					}
				}
			}
		}
		.slides > li {
			position: relative;
		}
		.flex-control-nav {
			bottom: 50px;
			
			@include breakpoint(lt-tablet) {
				bottom: 0px;
				left: 10px;
			}
			
			
			li {
				margin: 0;
				
				a {
					width: 50px;
					height: 10px;
					margin: 0 5px;
					background: $lightTeal;
					border-radius: 0;
					border: #fff;
					transition: .3s ease-in-out all;
					
					&:hover,
					&.flex-active {
						background: $teal;
					}
				}
			}
		}
	}
	

		&-caption {
			position: absolute;
			top: 45%;
			left: 6%;
			max-width: 50%;
			padding: 40px;

			@media (max-width: 767px) {
				padding: 10px;
				top: 0;
			}
		}
		&-content {
			.navicent-featured-tabs {
				display: block;
				position: absolute;
				top: 120px;
				left: 40px;
				width: 400px;
				z-index: 20;
			}
		}
}

.navicent-about-us {
	margin: 20px 0;
	
	&-tabs {
		padding: 0;
		margin: 0;
		
		@include breakpoint(tablet) {
			margin: 16px 0 0 0;
		}
	}
	
	@include breakpoint(desktop) {
		margin: 40px 0 60px;
		
		 h3 {
		 	margin-top: 1em;
		 	font-size: 24px;
		 	
		 	em {
		 		color: $darkTeal;
		 	}
		 }
	}
}

.navicent-services {
	&-items {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-around;
		margin: 0;
		padding: 0;
	}
	
	&-wrap-home {
		background: $darkTeal;
		padding: 20px 0;
		
		h2 {
			color: #fff;
			text-align: center;
		}
	}
	
	&-item {
		display: flex;
		flex-grow: 1;
		flex-basis: 0;
		position: relative;
		margin: 0;
		padding: 10px;
		width: 50%;
		min-width: 50%;
		font-weight: bold;
		font-size: 14px;
		text-align: center;
		
		&:nth-child(odd) {
			&:after {
				content: '';
				display: block;
				position: absolute;
				width: 3px;
				top: 0;
				right: -1px;
				bottom: 20px;
				background: rgba(136, 160, 168, .5);
			}
		}
		
		&:last-child {
			&:after {
				display: none;
			}
		}
		
		a {
			display: block;
			padding-bottom: 10px;
			width: 100%;
			color: #fff !important;
			border-bottom: 3px solid rgba(136, 160, 168, .5);
		}
		
		&:hover {
			.navicent-services-icon {
				border: 3px solid rgba($lightTeal, 1);
				box-shadow: 0 0 5px rgba(0,0,0,.5);
			}
		}
	}
	
	&-icon {
		display: block;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto 5px;
		width: 80px;
		height: 80px;
		border-radius: 50%;
		border: 3px solid rgba($lightTeal, .5);
		box-shadow: 0 0 5px rgba(0,0,0,0);
		transition: .3s ease-in-out border;
		
		svg {
			width: 100%;
			height: auto;
			max-width: 48px;
			max-height: 48px;
			
			path,
			ellipse,
			rect {
				fill: $lightTeal;
			}
		}
	}
	
	@include breakpoint(tablet) {
		&-item {
			font-size: 18px;
		}
	}
	
	@include breakpoint(desktop) {
		&-wrap-home {
			padding: 40px 0;
			margin-top: -50px;
			
			h2 {
				font-size: 36px;
			}
		}
		
		&-item {
			min-width: 33.33%;
			width: 33.33%;
			
			&:nth-child(odd) {
				&:after {
					content: '';
					display: none;
				}
			}
			
			&:nth-child(3n + 1),
			&:nth-child(3n + 2) {
				&:after {
					content: '';
					display: block;
					position: absolute;
					width: 3px;
					top: 0;
					right: -1px;
					bottom: 20px;
					background: rgba(136, 160, 168, .3);
				}
			}
			
			&:last-child {
				&:after {
					display: none;
				}
			}
			
			a {
				border-bottom: 3px solid rgba(136, 160, 168, .3);
			}
		}
	}
	
	&-interior {
		
	}
}

.navicent-footer {
	&-top {
		padding: 10px 0;
		margin: 0 0 30px 0;
		background: $lighterChar;
		
		ul,
		li {
			margin: 0;
			padding: 0;
		}
		
		a {
			color: #fff !important;
		}
	}
	
	&-social {
		li {
			display: inline-block;
		}
		
		a {
			font-size: 24px;
			padding-top: 5px;
			display: block;
			width: 30px;
			text-align: center;
		}
	}
	
	&-nav {
		margin-top: 20px;

		a {
			font-weight: bold;
			font-size: 11px;
		}
	}
	
	&-logo {
		display: block;
		margin: 20px auto 10px;
	}
	
	&-contact {
		span {
			display: inline-block;
			margin-bottom: 10px;
		}
		
		i {
			display: inline-block;
			width: 20px;
			text-align: center;
			color: $navgreendark;
		}
	}
	
	&-copyright {
		padding: 20px 0;
		background: $darkTeal;
		color: #fff !important;
		font-size: 14px;
		line-height: 18px;
	}
	
	@include breakpoint(desktop) {
		
		&-nav {
			margin-top: 0;
			text-align: right;
			
			li {
				display: inline-block;
				margin-left: 15px;
				
				a {
					display: block;
					padding-top: 10px;
				}
			}
		}
		
		&-awards {
			text-align: right;
			margin-top: 15px;
		}
	}
}

.branding {
	@extend %clearFix;

	@include breakpoint(lt-tablet) {
		.login-patient-portal {
			float: none;
			margin: 0 auto 20px;
			max-width: 350px;

			.patient-portal {
				float: none;
				font-size: 14px;
			}
		}
	}

	.for-healthcare-professionals {
		float: right;
		position: relative;
		text-align: right;
		color: $blue;
		text-decoration: none;
		width: 155px;
		margin-top: 10px;
		line-height: 1.2em;

		&:before {
			content: url(../ui/icon-arrow.png);
			background: #fff;
			height: 25px;
			width: 25px;
			display: block;
			position: absolute;
			text-align: center;
			padding-top: 5px;
			left: 10px;
			top: 5px;
			@include rounded(50%);
			@include box-shadow(1px,1px,5px,rgba(0,0,0,.2));
			@include transition();
		}
		&:hover {
			&:before {
				left: 15px;
			}
		}
		&:hover > div {
			left: auto;
			right: 0;
		}

		div {
			box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
			float: left;
			left: -999em;
			position: absolute;
			top: 100%;
			z-index: 99999;
			background: #f9f9f9;
			border: 2px solid #ccc;
			border-bottom: 2px solid $blue;
			border-top: none;
			-webkit-border-radius: 0 0 5px 5px;
			border-radius: 0 0 5px 5px;
			padding: 10px;

			ul {
				margin: 0;
				li {
					list-style: none;

					a {
						width: 260px;
						font-size: 14px;
						font-weight: normal;
						padding-top: 5px;
						padding-bottom: 5px;
						border: none;
						text-align: left;
						display: block;
						border-bottom: 1px solid #f1f1f1;

						&:hover {
						}
					}
				}
			}
		}
	}

	.ebola-button {
		&:after {
			padding-top: 4px;
		}
	}
}

.banner.wrap {
	border-top: 3px solid $darkTeal;
	max-width: 960px;
	width: 100%;

	img {
		display: none;
	}
}

.banner {
	margin-bottom: 30px;
}
.home .banner {
	display: none;
}

.home {
	@media (max-width: 767px) {
		position: relative;
	}
}

#quickmedDropdown {
	position: relative;
	@extend %clearFix;
	float: left;
	width: 26%;
	@include breakpoint(lt-tablet) {
		width: 100%;
	}
}
#quickmedDropdown ul {
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
	list-style: none;
	width: 272px;
	font-size: 14px;
	font-weight: normal;
	padding: 5px 10px 5px 20px;
	border: none;
	text-align: left;
	display: block;
	border-bottom: 1px solid #f1f1f1;
	position: absolute;
	top: 50px;
	z-index: 99999;
	background: #D1FEEC;
	border: 2px solid #ccc;
	border-bottom: 2px solid #f1f1f1;
	border-top: none;
	-webkit-border-radius: 0 0 5px 5px;
	margin: 0;

	@include breakpoint(lt-tablet) {
		width: 100%;
		left: 0;
		right: 0;
		margin: auto;
	}
}
#quickmedDropdown ul li {
	margin-top: 9px;
}
#quickmedDropdown ul li a:link,
#quickmedDropdown ul li a:visited {
	color: black;
	font-weight: bold;
}
#quickmedDropdown ul li a:hover,
#quickmedDropdown ul li a:active {
	color: #F00000;
}
.top-bar .quick-med {
	line-height: 2.2em;
	width: 100%;
}
.top-bar .quick-med:after {
	line-height: .2em;
}

/* Ebola Button */
.branding.wrap {
	position: relative;
}
.ebola-button {
	float: right;
	position: absolute;
	right: 20px;
	bottom: 30px;
	color: #C11C21 !important;
	font-size: 16px;
	padding-right: 40px;
}
.ebola-button:after {
	content: url(../ui/icon-arrow.png);
	background: #fff;
	height: 23px;
	width: 23px;
	display: block;
	position: absolute;
	text-align: center;
	padding-top: 2px !important;
	right: 10px;
	top: -1px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	-webkit-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
.ebola-button2 {
	background: #C11C21;
	display: block;
}
ul.subNavMenu {
	margin-left: 15px;
}

// Footer
.footer {
	border-top: 3px solid $blue;
	//background: $blue;
	padding: 2em 0;
	color: #404041;
	@extend %clearFix;

	a {
		color: #404041;
	}

	@include breakpoint(lt-tablet) {
		text-align: center;

		.footer-info,
		.footer-menu {
			width: 100%;
			float: none;
		}

		.footer-info {
			.footer-logo {
				margin: 0 auto;
			}

			.social {
				float: none;
				margin: 1em auto;

				ul {
					text-align: center;
					li {
						float: none;
						display: inline-block;
						margin: 0 10px;
					}
				}
			}

			.footer-contact {
				text-align: center;
			}
		}

		.footer-menu {
			ul {
				text-align: center;
				margin: 2%;
				li {
					margin: 3%;
				}
			}

			.footer-award {
				float: none;

				img {
					margin: 0 auto;
					display: block;
				}
			}
		}
	}

	.footer-info {
		float: left;
		width: 30%;

		.footer-logo {
			display: block;
			background: url(../ui/logo-footer-color.png) center center no-repeat;
			width: 196px;
			height: 49px;
			text-indent: -9999px;
		}
		.social {
			ul {
				@extend %clearFix;
				margin: 1em 0;

				li {
					list-style: none;
					float: left;
					margin: 0 20px 0 0;

					a {
						display: block;
						color:  #404040; //$blue;
						font-size: 20px;

						i {
							line-height: 50px;
						}
					}
				}
			}
		}
		.footer-contact {
			//@extend %clearFix;
			font-size: 14px;
			margin-bottom: 0;

			.phone {
				font-weight: bold;
				display: inline-block;
				margin-top: 1em;
			}
		}

		@include breakpoint(lt-desktop) {
			width: 25%;
		}

		@include breakpoint(lt-tablet) {
			float: none;
			width: 100%;
		}
	}
	.footer-menu {
		float: right;
		width: 70%;
		@extend %clearFix;

		ul {
			margin: 10px 0 0 0;
			text-align: right;

			li {
				list-style: none;
				margin-bottom: 1em;
				display: inline-block;
				margin-left: 20px;

				a {
					font-weight: bold;
				}
			}

			@include breakpoint(lt-tablet) {

			}
		}

		@include breakpoint(lt-desktop) {
			width: 75%;

			ul {
				li {
					margin-left: 10px;
				}
			}
		}
	}
}
#footerCopyright {
	float: right;
	width: 100%;
	text-align: center;
	@include breakpoint(lt-tablet) {
		float: none;
		text-align: center;
	}
}
.footer {
	margin-top: 30px;
}
.home .footer {
	margin-top: 0;
}

.navicent-footer-logo {
	img {
		max-width: 290px;
	}
}

@include breakpoint(lt-tablet) {
	.tt-dropdown-menu {
		max-width: 90vw;
	}
}

.content {
	.services-search-results {
		margin: 0;
		padding: 0;
		list-style: none;
		
		li {
			list-style: none;
			padding: 10px 0 5px;
			margin-bottom: 10px;
			border-bottom: 1px solid #ccc;
		}
	}
}

.community-slider-home {
	// background: #5d757d;
	background-image: url('../ui/bg-community-news.jpg');
	background-position: top center;
	background-size: cover;
	padding: 100px 20px 80px;
	
	@media (max-width: 767px) {
		padding: 20px 20px 40px;
	}
	
	h2 {
		color: $darkChar;
		margin: 0;
	    padding: 20px;
	    text-align: center;
	}
	
	h3 {
		margin-top: 0;
	}
	
	.newslist {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background: rgba($white,.9);
		border-radius: 10px;
		padding: 25px;
		margin: 5px;
		min-height: 350px;
		
		.btn-comm-news {
			margin: 0 auto;
		}
	
		&-slides {
			.col-4 {
				@media (max-width: 767px) {
					&:nth-child(2) {
						display: none;
					}
					
					&:nth-child(3) {
						display: none;
					}
				}
			}
		}
	}
	
	.flexslider {
		margin: 0;
		background: rgba(255,255,255,.9);
		box-shadow: rgba($darkTeal,.7) 0 0 10px;
		border-radius: 0 0 9px 9px;
	}
}


.for-visitors,
.for-patients > ul > li,
.for-visitors > ul > li {
	background: #fff !important;
}

#news_category {
    padding: 5px;
}

.has-gutter {
	&-right-half {
		margin-right: map-get($layout, gutters) / 2;
	}
	
	&-left-half {
		margin-right: map-get($layout, gutters) / 2;
	}
	
	@media (max-width: 767px) {
		&-mobile-none {
			margin: 0;
		}
	}
}