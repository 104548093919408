.content.full-width {
	width: 100%;
}
.service-search {
	margin: 30px auto;
	@extend %clearFix;

	@include breakpoint(tablet) {
		max-width: 60%;
	}
}
#CenterAddForm {
	width: 100%;

	#scrollable-dropdown-menu {

		label {
			float: left;
			font-size: 1.6em;
			margin-right: 20px;
		}
		input {
			background: #f1f1f1;
			border: none;
			@include box-shadow(0,0,10px,rgba(0,0,0,.2),true);
			height: 40px;
			width: 100%;
			padding: 0;
			padding-left: 10px;
		}

		.twitter-typeahead {
			display: block !important;
		}
	}

	@include breakpoint(lt-tablet) {
		width: 100%;

		#scrollable-dropdown-menu {
			label {
				float: none;
			}

			input {
				width: 100%;
				border: 1px solid $teal;
				border-radius: 3px;
			}
		}
	}
}
.view-all-centers {
	position: relative;
	top: 5px;
	display: block;
	padding-right: 40px;
	font-size: 18px;

	&:before {
		content: url(../ui/icon-arrow.png);
		background: #fff;
		height: 25px;
		width: 25px;
		display: block;
		position: absolute;
		text-align: center;
		padding-top: 5px;
		right: 0;
		@include rounded(50%);
		@include box-shadow(1px,1px,5px,rgba(0,0,0,.2));
		@include transition();
	}
	&:hover {
		&:before {
			right: 5px;
		}
	}
}
.tt-dropdown-menu {
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
	float: left;
	left: -999em;
	position: absolute;
	top: 100%;
	z-index: 99999;
	background: #f9f9f9;
	border: 2px solid #ccc;
	border-bottom: 2px solid $blue;
	border-top: none;
	-webkit-border-radius: 0 0 5px 5px;
	border-radius: 0 0 5px 5px;

	.tt-suggestion {
		border-bottom: 1px solid #f1f1f1;
		padding: 10px;
		cursor: pointer;

		&:hover {
			background: #f1f1f1;
		}
	}
}
.center-category {
	margin-bottom: 3em;
	h2 {
		border-bottom: 2px solid $blue;
	}
	.center-summary:last-child {
		border-bottom: none;
	}
}
.center-summary {
	border-bottom: 1px solid #ccc;
	@extend %clearFix;
	padding: 1.5em 0;

	.nav {
		display: flex;
		margin: .5em 0 1em;
		@extend %clearFix;

		li {
			flex: 0 1 auto;
			padding: 0 15px 0 0;
			margin-left: 0 !important;

			&:last-child {
				border-right: none;
			}
			a {
				font-size: 12px;
				font-weight: normal;
			}
		}

		@include breakpoint(lt-tablet) {
			display: none;
		}
	}
	p {
		margin-bottom: 0;
	}
	
	&__image {
		flex-basis: 170px;
		flex-shrink: 1;
		margin-right: 1rem;
		
		img {
			aspect-ratio: 350/150;
			width: 100%;
			height: auto;
			object-fit: cover;
			margin-bottom: -1rem;
			
			@media (max-width: 480px) {
				margin-bottom: 0;
			}
		}
		
		@media (max-width: 480px) {
			margin-bottom: 1rem;
			margin-right: 0;
			flex-basis: 100%;
		}
	}
	
	&__excerpt {
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 50%;
	}
}
.center.nav {
	li {
		border: none !important;
		a {
			line-height: 30px !important;
		}
	}
}
.center-sub-category {
	border-top: 2px solid #ddd;
	padding-top: 1em;

	.center-summary {
		padding-top: 0;
	}
}
.content {
	.button-list {
		padding: 1.5em;
		margin: 1.5em 0;
		list-style: none;
		@extend %clearFix;
		background: #f1f1f1;
		border: 1px solid #ddd;

		li {
			float: left;
			list-style: none;

			a {
				display: block;
				text-align: center;
				text-decoration: none;
				background: $darkTeal;
				padding: .25em .5em;
				margin: 0 10px;
				color: #fff;
			}
		}

		&.halves {
			li {
				width: 50%;
			}
		}
		&.thirds {
			li {
				width: 33.3333%;
			}
		}
		&.quarters {
			li {
				width: 25%;
			}
		}

		@include breakpoint(lt-tablet) {
			&.halves,
			&.thirds,
			&.quarters {
				li {
					width: 100%;
				}
			}
		}
	}

  .blue-title {
    clear: both;
    background: $darkTeal;
    padding: 5px 20px;
    margin-bottom: 1em;
    color: #fff;
  }

  .staff {
    .staff-item {
      @include breakpoint(tablet) {
        float: left;
        width: 47%;
        margin: 10px 1.5%;

        &:nth-child(2n+2) {
          clear: both;
        }
      }
      @include breakpoint(desktop) {
        float: left;
        width: 30%;
        margin: 10px 1.5%;

        &:nth-child(2n+2) {
          clear: none;
        }
        &:nth-child(3n+2) {
          clear: both;
        }
      }

      img {
        display: block;
        margin: 5px auto;
      }
    }
  }

	.photoimage {
		padding: 10px;
	}
}

// Speakers Bureau
.babysittingPage {
	label {
		float: left;

	}
}