.modal-container {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 99999;
	top: 0;
	left: 0;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: opacity 0.3s, visibility 0s linear 0.3s;
	transition: opacity 0.3s, visibility 0s linear 0.3s;

	.modal {
		max-width: 640px;
		margin: auto;
		position: absolute;
		z-index: 2;
		top: 10%;
		left: 20px;
		right: 20px;
		background: #FFF;
		border-radius: 5px;
		-webkit-transform: scale(0.8);
				transform: scale(0.8);
		-webkit-transition: top 0.2s, -webkit-transform 0.3s;
		transition: top 0.2s, -webkit-transform 0.3s;
		transition: transform 0.3s, top 0.2s;
		transition: transform 0.3s, top 0.2s, -webkit-transform 0.3s;
		
		&-title {
			background: $teal;
			color: #fff;
			padding: 10px;
			font-size: 18px;
			font-weight: bold;
			border-radius: 5px 5px 0 0;
		}
	}
}
.modal-container.active {
	-webkit-transition: opacity 0.3s, visibility 0s linear;
	transition: opacity 0.3s, visibility 0s linear;
	opacity: 1;
	visibility: visible;
}
.modal-container.active .modal {
	-webkit-transform: scale(1);
	transform: scale(1);
}
.modal-container .modal-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.7);
}
.modal-container .modal {
	
}
.modal-container .modal iframe {
	max-width: 100%;
}
.modal-container .modal-close {
	position: absolute;
	right: 20px;
	top: 5px;
	font-size: 24px;
	color: #CCC;
	text-decoration: none;
}
.modal-container .modal-close:hover {
	color: $lightTeal;
}

.modal-container .modal-body,
.modal-container .modal-footer {
	padding: 20px;
}

.modal-container .modal-footer {
	border-top: solid 1px #DDD;
	background: #F6F6F6;
	color: #999;
	font-size: 12px;
	text-align: right;
}

.reveal-modal-bg {
	position: fixed;
	height: 100%;
	width: 100%;
	background: #000;
	background: rgba(0,0,0,.8);
	z-index: 100;
	display: none;
	top: 0;
	left: 0;
}

.reveal-modal {
	visibility: hidden;
	top: 100px;
	//left: 50%;
	//margin-left: -520px;
	max-width: 1024px;
	background: #eee url('') no-repeat -200px -80px;
	position: absolute;
	z-index: 101;
	padding: 3%;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	-moz-box-shadow: 0 0 10px rgba(0,0,0,.4);
	-webkit-box-shadow: 0 0 10px rgba(0,0,0,.4);
	-box-shadow: 0 0 10px rgba(0,0,0,.4);
	margin: auto;
	left: 0;
	right: 0;

	@include breakpoint(lt-desktop) {
		max-width: 92%;
	}

	video,
	iframe {
		display: none;
	}

	&.active {
		video,
		iframe {
			display: block;
		}
	}
}

.reveal-modal.small 		{ width: 200px; margin-left: -140px;}
.reveal-modal.medium 		{ width: 400px; margin-left: -240px;}
.reveal-modal.large 		{ width: 600px; margin-left: -340px;}
.reveal-modal.xlarge 		{ width: 800px; margin-left: -440px;}

.reveal-modal .close-reveal-modal {
	font-size: 40px;
	line-height: .5;
	position: absolute;
	top: 15px;
	right: 11px;
	color: #6F6F6F;
	//text-shadow: 0 -1px 1px rbga(0,0,0,.6);
	font-weight: bold;
	cursor: pointer;
	text-decoration: none;
}
/*

NOTES

Close button entity is &#215;

Example markup

<div id="myModal" class="reveal-modal">
	<h2>Awesome. I have it.</h2>
	<p class="lead">Your couch.  I it's mine.</p>
	<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ultrices aliquet placerat. Duis pulvinar orci et nisi euismod vitae tempus lorem consectetur. Duis at magna quis turpis mattis venenatis eget id diam. </p>
	<a class="close-reveal-modal">&#215;</a>
</div>

*/

.box {
	background-color: #EFF8F8;
	padding: 15px 20px;
	margin: 5px auto;
	border-radius: 4px;
	border: 1px solid #27818A;
	font-family: sans-serif;
	width: 100%;
	
	.info {
		font-size: 16px;
	}
}

.location-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-size: 13px;
    //margin-top: 15px;
}

.upcoming-events {
	margin: 20px 0 40px;
	border: 1px solid #ccc;
	background: #f1f1f1;
	@extend %clearFix;

	-webkit-border-radius: 3px;
	border-radius: 3px;
	-webkit-box-shadow: 0 5px 5px rgba(0,0,0,.2);
	box-shadow: 0 5px 5px rgba(0,0,0,.2);

	li {
		padding: 15px;
		list-style: none !important;
		/* border-top: 1px solid #ddd; */
		float: left;
		width: 50%;

		h3 {
			text-transform: capitalize;
		}
		a {
			background: $blue;
			-webkit-border-radius: 5px;
			border-radius: 5px;
			color: #fff;
			padding: 3px 10px;
			display: inline-block;
			margin: 0 0 15px 0;
			text-decoration: none;
		}
		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: 0;
		}
		&:nth-child(1),
		&:nth-child(2) {
			border-top: none;
		}
		&:nth-child(odd) {
			border-right: 1px solid #ddd;
		}
		&:nth-child(odd) {
			clear: both;
		}
	}
}

#CorporateHealthFairIndexForm {
	.form-section {
		padding: 30px 0;
		margin-top: 30px;
		border-bottom: 1px solid #ccc;
	}
	.col-6 {
		float: left;
		width: 50%;
		padding: 0 10px;
	}
	.col-4 {
		float: left;
		width: 33.333333%;
		padding: 0 10px;
	}
	.col-8 {
		float: left;
		width: 66.66666666%;
		padding: 0 10px;
	}
	.col-12 {
		padding: 0 10px;
	}
	input[type="text"] {
		width: 100%;
	}
	.check {
		float: left;
		width: 50%;
	}
	.checkbox {
		float: left;
	    position: relative;
	    top: 5px;
	    margin-right: 5px;
	    
	    input {
	    	margin-right: 5px;
	    }
	}
	h3 {
		margin-top: 30px;
		border-top: 1px solid #ccc;
		padding-top: 30px;
	}
	.row {
		@extend %clearFix;
		margin-top: 10px;
	}
}

.coming-soon {
	text-align: center;
	display: block;
	text-decoration: none !important;
	color: initial;

	h2 {
		padding: 10px;
		margin: 0;
		background-color: $blue;
		color: #fff;
		text-transform: uppercase;
		position: relative;
		z-index: 2;

		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 100%;
			width: 0;
			height: 0;
			z-index: 1;
		}

		&:before {
			border-left: 10px solid transparent;
			border-right: 10px solid darken($blue, 10%);
			border-top: 10px solid darken($blue, 10%);
			border-bottom: 10px solid transparent;
			left: 0;
		}

		&:after {
			border-right: 10px solid transparent;
			border-left: 10px solid darken($blue, 10%);
			border-top: 10px solid darken($blue, 10%);
			border-bottom: 10px solid transparent;
			right: 0;
		}
	}

	&-interior {
		width: 94%;
		margin: 0 auto;
		position: relative;
		border: 1px solid #ccc;
		border-top: none;
		padding: 10px;
		background-color: #fff;
		z-index: 3;
		@extend %clearFix;
	}
}

.physician-callback {
	
	.logo {
		margin-bottom: 20px;
	}
	
	@media screen and (min-width: 768px) {
		.col-6 {
			float: left;
			width: 50%;
		}
		.col-4 {
			float: left;
			width: 33.333333%;
		}
		.col-8 {
			float: left;
			width: 66.66666666%;
		}
	}
	.col-6,
	.col-4,
	.col-8,
	.col-12 {
		padding: 0 10px;
	}
	
	.row {
		@extend %clearFix;
		margin: 0 -10px;
		
		@media screen and (min-width: 768px) {
			+ .row {
				margin-top: 10px;
			}
		}
	}
	
	.input {
		@media (max-width: 767px) {
			margin: 10px 0 0;
		}
	}
	
	label {
		font-size: 14px;
		display: block;
		text-align: left;
	}
	
	select,
	input[type="text"] {
		width: 100%;
		padding: 3px;
		color: #666;
		border: 1px solid #ccc;
	}
}

.foundation-campaign {
	margin: 20px 0 0;
	padding: 20px 0 0;
	border-top: solid 1px #ccc;
	
	img {
		max-width: 100%;
	}
	
	svg {
		min-height: 325px;
	}
}


/* homepage popup modal */

#modalPage
{
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px; left: 0px;
}
.modalBackground
{
    filter: Alpha(Opacity=40); -moz-opacity:0.4; opacity: 0.4;
    width: 100%; height: 100%; background-color: #000;
    position: fixed;
    z-index: 500;
    top: 0px; left: 0px;
}
.modalContainer
{
    position: fixed;
    width: 900px;
    left: 15%;
    top: 15%;
	z-index: 750;
	
	@media (max-width: 768px) {
		width: 600px;
		left: 10%;
	}

	@media (max-width: 500px) {
		width: 350px;
		left: 2%;
		top: 28%;
	}
}
.modal
{
    top: -150px;
    left: -150px;
    z-index: 1000;
    width: 900px;
    height: auto;
	padding: 0px;
	
	@media (max-width: 768px) {
		width: 600px;
	}

	@media (max-width: 500px) {
		width: 350px;
	}
}
.modalTop
{
	background: $teal;
	border-radius: 5px 5px 0 0;
    padding: 10px 10px 5px;
    color: #fff;
    text-align: right;
}
.modalTop a, .modalTop a:visited
{
    color: #fff;
}
.modalBody
{
    
}