.alert {
    display: block;
    width: 100%;
    border-radius: 5px;
    padding: 20px;
    margin: 10px auto;

    &-red {
        background: $red;
        color: #fff;
    }

    &-teal {
        background: $teal;
        color: #fff;
    }

    &-blue {
        background: $blue;
        color: #fff;
    }

    &-yellow {
        background: $yellow;
        color: $darkChar;
    }

    &-orange {
        background: $orange;
        color: $darkChar;
    }

    &-border {
        background: #fff;
        border: 2px solid $teal;
    }
    
    &-footer {
        padding: 8px 0 5px;
        text-align: center;
        color: $darkChar;
        
        a {
            color: $darkChar;
            
            &:hover {
                color: $teal;
                text-decoration: underline;
            }
        }
    }
}

#covidAlert {
    margin: 0;
    
    p {
        margin: 0;
    }
}