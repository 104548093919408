// Base Table Styles

$border: 1px solid #dddddd;

table {
    border-top: $border;
    border-left: $border;

    th {
        background: $teal;
        color: #fff;
        padding: 5px;
    }

    td {
        padding: 5px;
        border-right: $border;
        border-bottom: $border;
    }

    tr:nth-child(odd) {
        background: $lightCloud;
    }

    tr:hover {
        background: $lightTeal;
    }
}

.physician-table {
    border: $border;

    td, th {
        padding: 5px 10px;
        border-left: $border;
        &:first-child {
            border: none;
        }
    }

    th {
        a {
            position: relative;

            &:before {
                content: '';
                @include imageBlock(9px, 13px, url('../img/phys-table-sort.png') no-repeat);
                position: absolute;
                right: -14px;
                top: 6px;
            }

            &.asc {
                &:before {
                    background-position: -9px 0;
                }
            }

            &.desc {
                &:before {
                    background-position: -18px 0;
                }
            }
        }
    }

    tr {
        background-color: #fff;
        border-top: $border;
    }

    td {
        a:link, a:visited {
            text-decoration: underline;
        }
        a:hover, a:active {
            text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
        }
    }

    tbody {
        tr {
            &.altrow {
                background-color: #f0f0f0;
            }
        }
    }

    a.phone {
        @extend .navicent-btn;
        padding: 7px 5px 5px 25px !important;
        text-decoration: none !important;
        line-height: auto !important;

        &:before {
            content: '';
            position: absolute;
            top: 3px;
            left: 3px;
            display: block;
            width: 20px;
            height: 20px;
            background: url('../ui/icon-phone.png') left center no-repeat;
        }
    }

    .map-marker {
        text-align: center;
    }
    .map-icon {
        @include imageBlock(15px, 24px, url('../img/map-icon.png') no-repeat);
        display: inline-block;
        background-size: 15px 24px;
    }

    @include breakpoint(lt-tablet) {
        &.tablesaw-stack {
            tbody {
                tr {
                    box-shadow: inset 0 1px 1px rgba(255,255,255,.9);
                }
                td {
                    clear: none !important;
                    border: none !important;

                    .tablesaw-cell-label {
                        display: none !important;
                    }
                    .tablesaw-cell-content {
                        max-width: 100%;
                    }
                    &.name {
                        font-size: 16px;
                        font-weight: 500;
                        padding: 7px 3px 3px;
                    }
                    &.specialty {
                        display: none;
                    }
                    &.practice-name {
                        font-size: 10px;
                        padding: 3px 3px 7px;
                    }
                    &.name,
                    &.practice-name {
                        width: 70%;
                        float: left;
                    }
                    &.phone-wrap,
                    &.map-marker {
                        width: 13%;
                        float: right;
                        margin-top: -20px;
                        border: none;
                    }
                    &.phone-wrap {
                        margin-right: 3px;
                    }
                    .phone {
                        text-indent: -999em;
                        width: 20px;
                        height: 20px;
                        background: none;
                        border: none;
                        padding: 0;
                        top: 2px;

                        i {
                            display: block;
                        }

                        &:before {
                            background: url('../ui/icon-phone-blue.png') left center no-repeat;
                            top: 0;
                        }
                    }
                }
            }
        }
    }
}

.pagination {
    text-align: center;
    font-size: 0;

    span {
        display: inline-block;
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #ccc;
        border-right: 0px;
    }

    .number {
        width: 30px;

        &.current {
            background-color: $darkTeal;
            color: #eee;
        }
    }
    .next {
        border-right: 1px solid #ccc;
    }
    .previous,
    .next {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.physician-search {
    .search-bar {
        .clear-search {
            margin: -1px 0 0 10px;
        }
        @extend %clearFix;
        @include breakpoint(tablet) {
            float:right;
            margin-left:30px;
        }
        @include breakpoint(lt-tablet) {
            margin-top: 0;
            clear: left;
            
            .clear-search {
                margin-left: 0;
            }
        }

        .input.text {
            float: left;

            input[type="text"] {
                line-height: 31px;
                border-radius: 9px 0 0 9px;
                border: 2px solid #ddd;
                border-right: none;
                
                @media(min-width: 768px) {
                    min-width: 350px;
                }
            }
        }

        .submit {
            float: left;
            position: relative;
            top: -1px;
            margin-bottom: 15px;
            
            input {
                line-height: 31px;
                border-radius: 0 9px 9px 0;
                border: 2px solid $lightTeal;
                font-weight: bold;
                font-size: 14px;
            }
        }
    }
}

