.navicent-btn,
a.navicent-btn,
a.btn,
button.navicent-btn,
button.btn,
.content a.navicent-btn,
.content a.btn,
.submit input.navicent-btn {
    display: inline-block;
    position: relative;
    clear: both;
    width: auto;
    margin: 10px 0;
    padding: 12px 20px 10px 20px;
    background: $darkTeal;
    color: #fff !important;
    //font-weight: bold;
    font-size: 13px;
    //text-transform: uppercase;
    border-radius: 5px;
    //border: 2px solid $lightTeal;
    text-decoration: none;
    
    &:after {
        position: absolute;
        display: block;
        right: 18px;
        width: 24px;
        height: 24px;
        text-align: center;
        
        svg {
            display: block;
            margin: auto auto;
            width: auto;
            height: auto;
        }
    }
    
    &-full {
        width: 100%;
        
        &-center {
            text-align: center;
        }
    }
    
    &-article {
        padding-right: 50px;
        
        &:after {
            content: '';
            background-image: url('../img/icons/icon-document.svg');
            background-size: contain;
            background-repeat: no-repeat;
            display: block;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 50%;
            margin-top: -12px;
            right: 10px;
        }
    }
    &-building {
        padding-right: 50px;
        
        &:after {
            content: '';
            background-image: url('../img/icons/icon-buildings.svg');
            background-size: contain;
            background-repeat: no-repeat;
            display: block;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 50%;
            margin-top: -12px;
            right: 10px;
        }
    }
    &-news {
        padding-right: 50px;
        
        &:after {
            content: '';
            background-image: url('../img/icons/icon-news.svg');
            background-size: contain;
            background-repeat: no-repeat;
            display: block;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 50%;
            margin-top: -12px;
            right: 10px;
        }
    }
    
    &-apply {
        padding-right: 50px;
        
        &:after {
            content: '';
            background-image: url('../img/icons/btn-apply.svg');   
            background-repeat: no-repeat;
            display: block;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 50%;
            margin-top: -12px;
            right: 10px;
        }
    }
    &-status {
        padding-right: 50px;
        
        &:after {
            content: '';
            background-image: url('../img/icons/btn-status.svg');   
            background-repeat: no-repeat;
            display: block;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 50%;
            margin-top: -12px;
            right: 10px;
        }
    }
    &-join {
        padding-right: 50px;
        
        &:after {
            content: '';
            background-image: url('../img/icons/btn-join.svg');   
            background-repeat: no-repeat;
            display: block;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 50%;
            margin-top: -12px;
            right: 10px;
        }
    }

    &-blue {
        background: $blue;
        color: #fff;
    }

    &-yellow {
        background: $yellow;
        color: #000 !important;
    }

    &-red {
        background: $red;
        color: #fff;
    }
    
    &-community-news {
        width: 360px !important;
        font-size: 16px !important;
        text-align: center;
        font-weight: bold;
        
        @media (max-width: 767px) {
            width: 300px !important;
        }
    }
    
    &-alert {
        color: $darkChar;
        text-decoration: underline;
        font-weight: bold;
        
        &:hover {
            color: $teal;
        }
    }
}

.placeholder {
    height: 300px;
    text-align: center;
    background: #ccc;
    padding-top: 140px;
    margin-bottom: 30px;
}

img.hr-full {
    margin-bottom: 30px;
}

.margin {
    &-top {
        margin-top: 20px;
        
        &-big {
            margin-top: 40px;
        }
    }
}