// this was all moved from the developer.css file

.blog-intro {
    display: block;
    text-align: center;
    font-weight: bold;
    font-style: italic;
}

.physician-practices {

        width: 100%;

        .box {
            width: 50%;
            float: left;
        }

        .column {
            float: left;
            width: 50%;
            padding: 0 2.5%;

            h1 {
                font-size: 1.1em;

                a {
                    margin-bottom: 0;
                    text-decoration: none;

                    &:active,
                    &:hover {
                        font-weight: bold;
                        font-size: 105%;
                    }

                    &:after {
                        content: 'View Site »';
                        display: block;
                        font-size: .8em;
                        margin-top: .5em;

                        &:hover {
                            color: $red;
                            font-size: .12em;
                        }
                    }
                }
            }

            h2 {
                font-size: 1.3em;
                background: $lighterCloud;
                border: 1px solid $lighterChar;
                padding: 5px 10px;
                text-align: center;
                margin-bottom: 1em;
            }

            p {
                display: block;
                margin-bottom: 2em;

                span {
                    display: block;
                    padding-left: 9px;
                    font-size: 12px;
                    position: relative;
                    margin-left: 5px;

                    &:first-child {
                        margin-top: 1em;
                    }

                    &:before {
                        content: '•';
                        position: absolute;
                        left: 0;
                    }
                }
            }
        }

}

.boardImg {
    width: 120px;
    float: right;
}

.login-patient-portal {
    float: right;
    margin-top: 40px;
    text-align: center;
    max-width: 375px;

    .commercial {
        float: right;
        clear: both;
        color: #666;
        padding: 2px 0px 0px 30px;
        background: transparent url("../img/commercial.png") no-repeat scroll left center;
        height: 24px;
    }

    .btn-careers {
        display: inline-block;
        background-color: #48BB8D;
        padding: 5px 10px;
        font-size: 18px;
        color: #fff;
        width: 100%;
        text-align: center;

        &:hover,
        &:active {
            -moz-box-shadow: -1px -1px 1px rgba(0,0,0,0.3);
            -webkit-box-shadow: -1px -1px 1px rgba(0,0,0,0.3);
            box-shadow: 3px 3px 4px rgba(0,0,0,0.3);
            text-shadow: 3px 3px 4px rgba(0,0,0,0.3);
            color: whitesmoke;
        }
    }
}

.patient-portal {
    background-color: #C11C21;
    color: #fff;
    float: right;
    padding: 5px 10px;
    display: inline-block;
    clear: both;
    font-size: 18px;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;

    &:visited {
        background-color: #C11C21;
        color: #fff;
        float: right;
        padding: 5px 10px;
        display: inline-block;
        clear: both;
        font-size: 18px;
        margin-bottom: 10px;
        width: 100%;
        text-align: center;
    }

    a {

        &:hover,
        &:active {
            -moz-box-shadow: -1px -1px 1px rgba(0,0,0,0.3);
            -webkit-box-shadow: -1px -1px 1px rgba(0,0,0,0.3);
            box-shadow: 3px 3px 4px rgba(0,0,0,0.3);
            text-shadow: 3px 3px 4px rgba(0,0,0,0.3);
            color: whitesmoke;
        }
    }

    .request {
        background-color: #274469;

        &:hover {
            color: #cccccc;
        }
    }
}

a.physicianDirBtn:hover, a.physiciandirbtn:active {
    -moz-box-shadow: -1px -1px 1px rgba(0,0,0,0.3);
    -webkit-box-shadow: -1px -1px 1px rgba(0,0,0,0.3);
    box-shadow: 3px 3px 4px rgba(0,0,0,0.3);
    text-shadow: 3px 3px 4px rgba(0,0,0,0.3);
    color: whitesmoke;
}

@media (max-width: 767px) {
    .login-patient-portal .commercial {
        /*float: none;*/
        margin: 0 auto;
    }

    .login-patient-portal .btn-careers {
        padding: 2px 10px;
    }
}

.footer-award {
	float: right;
	display: block;
	margin: 1em 0;
}

.flash {
    background: #FCF9CE;
	clear: both;
	color: #444;
	font-weight: bold;
	margin: 10px 0;
    padding: 10px;
    
    .error {
        background: #FFEBE8;
        color: #900;
    }

    .success {
        background: #CEE6C3;
        color: #128642;

        a {
            color: #555;
	        font-weight: normal;
        }
    }
}


.breadcrumbs-trail {
    padding-left:0px;
    margin-left: 0px;

    li {
        text-decoration: none;
        display: inline;
        margin-right: 3px;
        padding: 0 !important;
        border-bottom: none !important;
        margin-left: 0 !important;

        a {
            color: $teal;

            &:hover {
                color: $darkTeal;
            }
        }
    }
}

#CenterPageViewBMIForm {
    .contactForm {
        div {
            margin-bottom: 0.25em;
        }
    }

    .btn {
        width: 100%;
    }
}

#overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #333;
    z-index: 2;
    cursor: pointer;

    #text {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 50px;
        color: white;
        transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        background: #222;
        min-height: 25%;
        width: 55%;
        padding: 20px;
    }
}

h2.search-title,
.content .news-preview-item-title {
    font-size: 18px !important
}