// Typography
body,
button,
input,
select,
textarea {
	color: #404040;
	font-family: $bau;
	font-size: 16px;
	line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5 { 
	font-family: $bauBold;
	//font-weight: bold
	line-height: 1.2em;
	margin-bottom: .5em;
}

p {
	margin-bottom: 1em;
}

b,
strong {
	font-weight: bold;
}

dfn,
cite,
em,
i {
	font-style: italic;
}

blockquote {
	margin: 0 1.5em;
}

address {
	margin: 0 0 1.5em;
}

pre {
	background: #eee;
	line-height: 1.6;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}

code,
kbd,
tt,
var {

}

abbr,
acronym {
	border-bottom: 1px dotted #666;
	cursor: help;
}

mark,
ins {
	background: #fff9c0;
	text-decoration: none;
}

sup,
sub {
	font-size: 75%;
	height: 0;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	bottom: 1ex;
}

sub {
	top: .5ex;
}

small {
	font-size: 75%;
}

big {
	font-size: 125%;
}


// Style Elements
hr {
	background-color: #ccc;
	border: 0;
	height: 1px;
	margin-bottom: 1.5em;
}

ul,
ol {
	margin: 0 0 1.5em 3em;
}

ul {
	list-style: disc;
}

ol {
	list-style: decimal;
}

li > ul,
li > ol {
	margin-bottom: 0;
	margin-left: 1.5em;
}

dt {
	font-weight: bold;
}

dd {
	margin: 0 1.5em 1.5em;
}

img {
	height: auto; /* Make sure images are scaled correctly. */
	max-width: 100%; /* Adhere to container width. */
}

figure {
	margin: 0;
}

table {
	margin: 0 0 1.5em;
	width: 100%;
}

th {
	font-weight: bold;
}


/// Forms
button,
input,
select,
textarea {
	font-size: 16px; /* Corrects font size not being inherited in all browsers */
	margin: 0; /* Addresses margins set differently in IE6/7, F3/4, S5, Chrome */
	vertical-align: baseline; /* Improves appearance and consistency in all browsers */
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	background: $teal;
	color: #fff;
	padding: 10px 40px;
	border: none;
	border-radius: 3px;
	cursor: pointer; /* Improves usability and consistency of cursor style between image-type 'input' and others */
	-webkit-appearance: button; /* Corrects inability to style clickable 'input' types in iOS */

	&:hover {
		border-color: #bbb;
	}

	&:focus,
	&:active {
	}
}

input[type="checkbox"],
input[type="radio"] {
	padding: 0; /* Addresses excess padding in IE8/9 */
}

input[type="search"] {
	-webkit-appearance: textfield; /* Addresses appearance set to searchfield in S5, Chrome */
	-webkit-box-sizing: content-box; /* Addresses box sizing set to border-box in S5, Chrome (include -moz to future-proof) */
	-moz-box-sizing:    content-box;
	box-sizing:         content-box;

	&::-webkit-search-decoration { /* Corrects inner padding displayed oddly in S5, Chrome on OSX */
		-webkit-appearance: none;
	}
}

button::-moz-focus-inner,
input::-moz-focus-inner { /* Corrects inner padding and border displayed oddly in FF3/4 www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/ */
	border: 0;
	padding: 0;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
textarea {
	color: #666;
	border: 1px solid #ccc;

	&:focus {
		color: #111;
	}
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"] ,
input[type="tel"] {
	padding: 3px;
}

textarea {
	overflow: auto; /* Removes default vertical scrollbar in IE6/7/8/9 */
	padding-left: 3px;
	vertical-align: top; /* Improves readability and alignment in all browsers */
	width: 100%;
}

.hidden {
	display: none;
}

.text-center {
	text-align: center;
}

a,
a:hover,
a:visited {
	color: $darkTeal;
	text-decoration: none;
}

*:focus {
	border: 1px dotted #007078;
}

.clearfix {
	@extend %clearFix;
}
