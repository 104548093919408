// Content

h1 { 
	font-size: 36px;
}

h2 { 
	font-size: 26px; 
}

h3 { 
	font-size: 18px;
}

p { 
	font-size: 16px;
	margin-bottom: 1em; 
}


@include breakpoint(lt-mobile) {
	h1 { 
		font-size: 21px;
	}
	
	h2 { 
		font-size: 18px; 
	}
	
	h3 { 
		font-size: 16px; 
	}
	
	p { 
		font-size: 15px;
		margin-bottom: 1em; 
	}
}

.sidebar {
	float: left;
	width: 29%;
	
	.left-menu {
		text-align: left; 
		//margin-top: 10px;
	}

	@include breakpoint(lt-tablet) {
		background: none;
		border: 1px solid $darkTeal;
		border-radius: 7px;
		color: #404040;
		padding: 5px 10px;

		ul li a {
			color: #404040;
		}
	}
}    
.content {
	float: right;
	//max-width: 640px;
	font-size: 14px;
	width: 65%;
	padding: 30px 0;

	// .pages > :first-child {
	// 	font-size: 2em;
	// }
	&.content-full {
		width: 100%;
	}

	ul {
		li {
			list-style: disc;
			padding: 10px 5px;
			border-bottom: 1px solid $darkCloud;
			//margin-left: -20px;
		}
	}
	a {
		text-decoration: underline;
	}
	.inner-content-box {
		border-bottom: 1px solid $lightCloud;
		padding: 1em 0;

		p {
			word-break: break-word;
		}
	}
	hr {
		clear: both;
	}

	/* Generic Styling */
	.align-right {
		float: right;
		max-width: 45%;
		margin: 0 0 20px 20px;
	}
	.align-left {
		float: left;
		max-width: 45%;
		margin: 0 20px 20px 0;
	}

	@include breakpoint(lt-tablet) {

		.align-left,
		.align-right {
			float: none;
			max-width: 100%;
			margin: 5% 0;
		}
	}
	.full {
		width: 100%;
		clear: both;
	}
	.content-box {
		background: #f1f1f1;
		border: 1px solid #ccc;
		padding: 20px;

		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}

@include breakpoint(lt-tablet) {
	.sidebar,
	.content {
		width: 100%;
	}
}

ol li { list-style: inherit; }

/* Form Styling */
#h5f_form div {
	clear: both;
	margin: .5em 0;
}

#h5_form .ui-accordion-content {
	margin-top: 0;
}

#h5f_form .text label,
#h5f_form .tel label,
#h5f_form .email label,
#h5f_form .select label {
	float: left;
	width: 200px;
}

#h5f_form .radio label {
	float: none;
	margin-right: 5px;
	display: block;
}


/* Tables */
.contentBody table {
	border: 1px solid #ccc;
	background: #f1f1f1;
}
.contentBody td {
	border-right: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	padding: 3px;
	text-align: center;
}
.contentBody blockquote {
	margin-left: 0;
	padding-left: 20px;
	border-left: 3px solid #ccc;
}

/* Accordion */
.faculty-accordion.ui-accordion .ui-accordion-header {
	padding-left: 30px;
	padding: 10px 30px;
}
.faculty-accordion.ui-accordion .ui-accordion-content {
	font-size: 13px;
}
.faculty-accordion.ui-accordion .ui-accordion-content h3 {
	border-top: 2px solid #ccc;
	padding-top: 20px;
	margin-top: 10px;
}

/* Gallery */
.service-gallery {
	margin: 0;
	padding: 0;
	list-style: none;
	clear: both;
}
.service-gallery li {
	list-style: none !important;
	float: left;
	margin: 12px 2.5%;
	width: 20%;
	height: 100px;
	overflow: hidden;
}
.service-gallery li img {
	min-height: 100%;
	min-width: 100%;
}

/* Contact page/maps */
.map-preview {
	float: right;
	width: 308px;
	background: #f1f1f1;
	padding: 10px;
	margin: 20px 0 20px 20px;
	border: 1px solid #ccc;
	text-align: center;

	@include breakpoint(lt-tablet) {
		max-width: 100%;
	}
}
.map-preview a {
	text-decoration: none;
}
.map-preview img {
	margin-bottom: 5px;
	border: 1px solid #ccc;
}
.map-details {
	padding-top: 20px;
}
.map-details a {
	color: $red;
}

/* Calendar */
.calendar-legend {
	background: #f1f1f1;
	border: 1px solid #ddd;
	padding: 20px 20px 20px 10px;
}
.calendar-legend h2 {
	font-size: 18px;
	margin-left: 10px;
}
.calendar-legend ul {
	margin-bottom: 0;
	list-style: none;
}
.calendar-legend ul li {
	list-style: none;
	position: relative;
	margin-bottom: 10px;
}
.calendar-legend li:before {
	content: '';
	display: block;
	width: 20px;
	height: 20px;
	border: 1px solid #ddd;
	position: absolute;
	left: -30px;
}
.calendar-legend .wellness-fitness:before {
	background: #fff;
}
.calendar-legend .macon-fitness:before {
	background: #E0CE8C;
}
.calendar-legend .special-classes {
	margin-bottom: 0 !important;
}
.calendar-legend .special-classes:before {
	background: #26CBD1;
}

/* Misc */
#flashMessage,
.flashMessage {
	padding: 10px;
	color: white;
	background-color: $darkTeal;
	font-weight: bold;
	text-align: center;
	margin-bottom: 20px;
}
.login input {
	margin-left: 10px;
}
.jacked_button {
	margin: 20px 0px;
	display: inline-block;
	background: none repeat scroll 0% 0% #555;
	padding: 7px 15px !important;
	border-radius: 3px;
	color: #FFF;
	text-decoration: none;
	border: medium none;
}
.jacked_button {
	margin: 20px 0px;
	display: inline-block;
	background: none repeat scroll 0% 0% #555;
	padding: 7px 15px !important;
	border-radius: 3px;
	color: #FFF;
	text-decoration: none;
	border: medium none;
}
.childrens-rendering {
	margin: 20px 0;
}

// Calendar
@font-face {
  font-family: 'fontawesome-selected';
  src: url("../font/fontawesome-selected.eot");
  src: url("../font/fontawesome-selected.eot?#iefix") format('embedded-opentype'), url("../font/fontawesome-selected.woff") format('woff'), url("../font/fontawesome-selected.ttf") format('truetype'), url("../font/fontawesome-selected.svg#fontawesome-selected") format('svg');
  font-weight: normal;
  font-style: normal;
}

.fc-calendar-container {
	position: relative;
	height: 600px;
	width: 100%;
}

.fc-calendar {
	width: 100%;
	height: 100%;
}

.fc-calendar .fc-head {
	height: 30px;
	line-height: 30px;
	background: $darkTeal;
	color: #fff;
	font-size: 14px;
	font-weight: bold;
}

.fc-calendar .fc-body {
	position: relative;
	width: 100%;
	height: 100%;
	height: -moz-calc(100% - 30px);
	height: -webkit-calc(100% - 30px);
	height: calc(100% - 30px);
	border: 1px solid #ddd;
}

.fc-calendar .fc-row {
	width: 100%;
	border-bottom: 1px solid #ddd;
}

.fc-four-rows .fc-row  {
	height: 25%;
}

.fc-five-rows .fc-row  {
	height: 20%;
}

.fc-six-rows .fc-row {
	height: 16.66%;
	height: -moz-calc(100%/6);
	height: -webkit-calc(100%/6);
	height: calc(100%/6);
}

.fc-calendar .fc-row > div,
.fc-calendar .fc-head > div {
	float: left;
	height: 100%;
	width:  14.28%; /* 100% / 7 */
	width: -moz-calc(100%/7);
	width: -webkit-calc(100%/7);
	/* width: calc(100%/7); */ 
	position: relative;
}

/* IE 9 is rounding up the calc it seems */
.ie9 .fc-calendar .fc-row > div,
.ie9 .fc-calendar .fc-head > div {
	width:  14.2%;
}

.fc-calendar .fc-row > div {
	border-right: 1px solid #ddd;
	padding: 4px;
	overflow: hidden;
	position: relative;
}

.fc-calendar .fc-head > div {
	text-align: center;
}

.fc-calendar .fc-row > div > span.fc-date {
	position: absolute;
	width: 24px;
	height: 24px;
	font-size: 12px;
	line-height: 24px;
	background: $darkTeal;
	border-radius: 50%;
	color: #fff;
	bottom: 5px;
	right: 5px;
	text-align: center;
}

.fc-calendar .fc-row > div time {
	display: none;
}

.fc-calendar .fc-row > div > span.fc-weekday {
	padding-left: 5px;
	display: none;
}

.fc-calendar .fc-row > div.fc-today {
	background: #fff4c3;
}

.fc-calendar .fc-row > div.fc-out {
	opacity: 0.6;
}

.fc-calendar .fc-row > div:last-child,
.fc-calendar .fc-head > div:last-child {
	border-right: none;
}

.fc-calendar .fc-row:last-child {
	border-bottom: none;
}


.responsive-calendar {
	.custom-month-year {
		margin-bottom: 20px;
		
		nav {
			float: right;
			margin-bottom: 10px;
		}
	}
	.custom-prev,
	.custom-next {
		display: inline-block;
		width: 30px;
		height: 30px;
		background: rgba(0,0,0,.3);
		text-align: center;
		line-height: 30px;
		font-weight: bold;
		color: #fff;
		cursor: pointer;
	}
	
	.calendar-event {
		position: relative;
		display: block;
		border: 1px solid #ccc;
		padding: 2px 5px;
		border-radius: 3px;
		margin-bottom: 5px;
		background: #f1f1f1;
		font-size: 11px;
		cursor: pointer;
	}
	
	.fc-row {
		> div {
			a {
				text-decoration: none;
			}
			
			&.fc-today {
				background: rgba(39,68,105,.15);
				
				.calendar-event {
					background: #fff;
				}
			}
			&.fc-past {
				span {
					opacity: .3;
					transition: .3s ease-in-out all;
				}
				
				&:hover {
					span {
						opacity: 1;
					}
				}
			}
			&.fc-future {
				transition: .3s ease-in-out all;
				
				.calendar-event {
					transition: .3s ease-in-out all;
				}
				
				&:hover {
					background: rgba(39,68,105,.15);
					
					.calendar-event {
						background: #fff;
					}
				}
			}
		}
	}
	
	@include breakpoint(lt-tablet) {
		.fc-head {
			display: none;
		}
		.fc-row {
			border: none;
			
			> div {
				display: block;
				width: 100%;
				border-top: none;
				border-right: 1px solid #ddd;
				border-bottom: 1px solid #ddd;
				border-left: 1px solid #ddd;
				height: auto;
				min-height: 60px;
				
				span {
					&.fc-weekday {
						display: block;
						position: absolute;
						right: 15px;
						bottom: 7px;
						background: rgba(39,68,105,.8);
						padding-right: 20px;
						color: #fff;
						border-radius: 10px;
						height: 20px;
						font-size: 10px;
						line-height: 20px;
						z-index: -1;
					}
				}
				
				&.fc-past {
					display: none;
				}
			}
			> div:last-child {
				display: block;
				width: 100%;
				border-top: none;
				border-right: 1px solid #ddd;
				border-bottom: 1px solid #ddd;
				border-left: 1px solid #ddd;
			}
		}
	}
}

.tooltipster-navicent-customized {
	background: none;
	
	@include breakpoint(tablet) {
		margin-left: 40px;
	}
	
	.tooltipster-box {
		border: 1px solid #ccc !important;
		padding: 2px 5px;
		border-radius: 3px;
		margin-bottom: 5px;
		background: #f1f1f1 !important;
		font-size: 11px;
	    
	    .tooltipster-content {
			color: #333;
	    }
	}
}

.content {
	.news-preview {
		&-wrapper,
		&-item {
			display: block;
			margin: 0;
			padding: 0;
			list-style: none;
		}
		&-item {
			display: flex;
			margin-bottom: 20px;
			background: linear-gradient(to right, #fff 1%, #fff 45%, rgba($darkTeal, .15) 100%);
			background-size: 200% 100%;
			background-position: 2% 0;
			border: 1px solid rgba($darkTeal, .1);
			border-radius: 3px;
			overflow: hidden;
			box-shadow: 0 3px 5px rgba($darkTeal, .15);
			text-decoration: none;
			cursor: pointer;
			transition: .3s ease-in-out all;
			
			&:hover {
				background-position: 100% 0;
				box-shadow: 0 0 0 rgba($darkTeal, 0);
				
				.news-preview-item-title {
					// color: #fff;
				}
				.news-preview-item-date {
					// background: #fff;
					// color: $darkTeal;
				}
				.news-preview-item-more {
					opacity: 1;
				}
			}
			
			&-content {
				flex-basis: 70%;
				position: relative;
				padding-bottom: 25px;
			}
			&-title {
				color: $darkTeal;
				padding: 20px;
				transition: .3s ease-in-out all;
			}
			&-date,
			&-more {
				position: absolute;
				bottom: 0;
				display: block;
				width: 90px;
				background: $darkTeal;
				padding: 5px 0;
				text-align: center;
				color: #fff;
			}
			&-date {
				left: 0;
				transition: .3s ease-in-out all;
			}
			&-more {
				right: 0;
				background: #fff;
				color: $darkTeal;
				opacity: 0;
				transition: .3s ease-in-out all;
			}
			&-thumbnail {
				flex-basis: 30%;
				max-height: 160px;
				overflow: hidden;
				
				img {
					display: block;
					min-height: 100%;
				}
			}
		}
		
		@include breakpoint(lt-tablet) {
			&-item {
				&-content {
					flex-basis: 100%;
				}
				&-thumbnail {
					display: none;
				}
			}
		}
	}
	.single-news-image {
		float: right;
		max-width: 45%;
		margin: 0 0 20px 20px;
	}
}

.media-resources {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	a {
		text-decoration: none;

		&:hover {
			color: $darkTeal;
		}
	}
	
	&-item {
		display: flex;
		flex-direction: column;
		width: 45%;
		margin-bottom: 20px;
		background: linear-gradient(to right, #fff 1%, #fff 45%, rgba($darkTeal, .15) 100%);
		background-size: 200% 100%;
		background-position: 2% 0;
		border: 1px solid rgba($darkTeal, .1);
		border-radius: 3px;
		overflow: hidden;
		box-shadow: 0 3px 5px rgba($darkTeal, .15);
		text-decoration: none;
		transition: .3s ease-in-out all;
		padding: 20px;
		
		&-thumbnail {
			display: flex;
			justify-content: center;
			height: 160px;
			text-align: center;
			overflow: hidden;
			
			img {
				display: block;
				height: 160px;
				width: auto;
				max-width: none;
				margin: 0 auto;
			}
		}
		&-title {
			margin: 5px 0;
			color: $teal;
		}
	}
	
	@include breakpoint(lt-tablet) {
		&-item {
			width: 100%;
		}
	}
}

@keyframes smooth {
	0% { background-position: 10px 5px; }
	25% { background-position: 10px 4px; }
	50% { background-position: 10px 5px; }
	75% { background-position: 10px 6px; }
	100% { background-position: 10px 5px; }
}
