.event-details {
	padding: 20px;
	border: 1px solid #ccc;
	background: #f1f1f1;

	-webkit-border-radius: 3px;
	border-radius: 3px;
	-webkit-box-shadow: 0 5px 5px rgba(0,0,0,.2);
	box-shadow: 0 5px 5px rgba(0,0,0,.2);
}
.event-details:after {
	content: "";
	display: table;
	clear: both;
}
.event-details p {
	margin-bottom: 1em;
}
.event-details .space {
	display: inline-block;
}
.event-details div {
	border-bottom: 1px solid #ccc;
	padding: 7px 0;
}
.event-details div:last-child {
	border-bottom: none;
}
.event-details p:last-child {
	border-bottom: 0;
	padding-bottom: 0;
	margin-bottom: 0;
}
.event-details img {
	display: block;
	float: right;
	max-width: 40%;
	margin: 0 0 20px 20px;

	@include breakpoint(lt-tablet) {
		float: none;
		margin: 20px auto;
		max-width: 100%;
	}
}
.event-payment {
	clear: both;
	margin-top: 30px;
}
.event-form {
	border: 1px solid #ccc;
	padding: 20px 20px 0 20px;
	background: #f1f1f1;
	margin-bottom: 30px;

	-webkit-border-radius: 3px;
	border-radius: 3px;
	-webkit-box-shadow: 0 5px 5px rgba(0,0,0,.2);
	box-shadow: 0 5px 5px rgba(0,0,0,.2);
}
.event-form .input {
	margin-bottom: 7px;
}
.event-form label {
	width: 160px;
	display: inline-block;
}
// .event-form input {
// 	width: 436px;
// }
// .event-form input[type='radio'] {
// 	width: 36px;
// }

.event-form {
	.registration-radio {
		label,
		input {
			display: inline;
		}
		
		input {
			margin-right: 10px;
		}
		
		+ .registration-radio {
			margin-top: 10px;
		}
	}
}
.event-form input,
.event-form select {
	max-width: 436px;
	width: 100%;
}
.event-form input[type='radio'] {
	width: 36px;
}

.event-form .submit {
	margin-bottom: 20px;
}
.event-form .submit input {
	width: 100%;
	padding: 10px;
	margin-top: 10px;
}
div.event-form .error-message {
	color: #C11C21;
	font-style: oblique;
}