form {
	background: $lighterTeal;
	padding: 20px;
	border: 1px solid $lightTeal;
	border-radius: 3px;

	.submit {

		input {
			background: $teal;
			color: #fff;
			padding: 10px 40px;
			border: none;
			border-radius: 3px;
		}
	}
}

.navicent-form {
    &-input {
        @extend %clearFix;
        margin-bottom: 10px;
        
        &:last-child {
            margin-bottom: 0;
        }
        
        input,
        textarea,
        select {
            margin:0;
        	border:0;
        	padding:0;
        	display:inline-block;
        	vertical-align:middle;
            white-space:normal;
            border-radius: 9px;
            border: 2px solid $darkTeal;
            color: $darkTeal;
            vertical-align: middle;
        }
        input,
        select {
            height: 40px;
            line-height: 40px;
            padding: 0 7px;
        }
        input[type="submit"] {
            background: $darkTeal;
            color: #fff;
        }
    }
}

.pfinder {
	label {
		float: left;
		width: 250px;
	}
}

.link_wrapper {
	div {
		margin-bottom: .5em;

		label {
			float: left;
			width: 150px;
		}

		input[type="submit"] {
			background: $blue;
			padding: .25em .5em;
			color: #fff;
		}
	}
}
.registrationForm,
.needsForm,
.indemnificationForm {
	margin-bottom: 2em;

	div {
		margin-bottom: .5em;

		label {
			float: left;
			width: 150px;
		}

		input[type="submit"] {
			background: $blue;
			padding: .25em .5em;
			color: #fff;
		}
	}
}
.contactForm {
	font-size: 14px !important;
	margin-bottom: 2em;

	h4 {
		margin-top: 2em;
	}
	div {
		margin-bottom: 1em;
	}
	label {
		margin-bottom: 1em;
	}
	label[for=ContactName],
	label[for=ContactSubject] {
		float: left;
		width: 200px;
	}
	label[for=ContactEmail] {
		@extend %clearFix;
	}
	#ContactEmail {
		margin-top: .5em;
	}
	input {
		//height: 20px;
		//line-height: 20px;
		display: block;
		width: 100%;
		border-radius: 3px;
	}
}
#Complaint,
.contactFormPeach,
.complaintFormPeach{
	font-size: 14px !important;

	margin-bottom: 2em;

	h4 {
		margin-top: 2em;
		border-top: 1px solid #ccc;
		padding-top: 2em;

		&:first-child {
			margin-top: 0;
			border-top: none;
		}
	}
	div {
		margin-bottom: 1em;
	}
	label {
		margin-bottom: .5em;
		float: left;
		width: 200px;
	}
	.radio label {
		float: none;
	}
	label[for=ContactName],
	label[for=ContactSubject] {
		float: left;
		width: 200px;
	}
	label[for=ContactEmail] {
		@extend %clearFix;
	}
	#ContactEmail {
		margin-top: .5em;
	}
	input {
		//height: 20px;
		//line-height: 20px;
	}
	input#ComplaintAddress2 {
		//margin-left: 200px;
	}
}
.boothForm {
	margin-bottom: 2em;

	fieldset {
		margin-bottom: .75em;
	}
	legend {
		font-weight: 600;
	}
	label {
		float: left;
		width: 50px;
	}
	input {
		float: left;
		margin: 3px 5px 0 0;
	}
}
.submit {
	margin-bottom: 2em;
}


.flat-custom {
	line-height: 20px;
	height: 20px;
	position: relative;
	display: block;
	padding-left: 26px;
	cursor: pointer;
	box-sizing: border-box;
	transition: all 0.2s ease-in-out;

	&::before {
		height: 20px;
		width: 20px;
		border: 1px solid #ACBABF;
		background: #fff;
	}

	&::after {
		opacity: 1;
		border-color: $teal;
	}

	&:checked {
		background: $teal;
	}
}

.ng-select-container {
	box-shadow: none !important;
}