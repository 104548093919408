.mobile-hidden {
	display: block !important;
}

.mobile-visible {
	display: none !important;
}

.nav-button {
	float: right;
	padding: 5px;
	margin-top: 10px;
}

.hidden {
	display: none;
}

.responsive-menu-checkbox,
.responsive-menu {
	display: none;
}

iframe {
	max-width: 100%;
}

@include breakpoint(lt-tablet) {
	.mobile-hidden {
		display: none !important;
	}

	.mobile-visible {
		display: block !important;
	}

	//Sidebar responsive mobile menu
	.responsive-menu {
		display: block;
		border-radius: 5px;
		position: relative;

		&:before {
			content: '';
			display: block;
			height: 0;
			width: 0;
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			border-top: 8px solid $darkTeal;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			transition: all .2s ease;
		}

		.about & {
			~ ul {
				.grandchildren {
					display: none;
				}	
			}
		}
	}

	.responsive-menu-checkbox {
		display: none;
		
		~ ul,
		~ div {
			max-height: 0;
			opacity: 0;
			transition: .3s ease all;
			margin: 0 !important;
    		padding: 0 !important;
    		

			a,p {
				height: 0;
				padding: 0 !important;
				transition: .3s ease all;
			}

			h3, a, p {
				color: #404040;
			}
		}

		&:checked {
			
			~ ul,
			~ div {
				max-height: 1000px;
				opacity: 1;

				a {
					height: auto;
					padding: 5px 0 !important;
				}
				p {
					height: auto;
				}
			}
			
			~ .responsive-menu {

				&:before {
					border-bottom: 8px solid $darkTeal;
					border-left: 8px solid transparent;
					border-right: 8px solid transparent;
					border-top: none;
				}
			}
		}
	}
	
	//SC content classes
	.crescent-third {
		width: 100%;
		float: none;
		margin-bottom: 20px;
	}

	.content.physician-practices {
		.box {
			float: none;
			width: 100%;
		}

		.column {
			float: none;
			padding: 0;
			width: 100%;
		}
	}

	table {
		word-break: break-word;
	}
}

//Service Center content classes
.contentBody {
	@extend %clearFix;
}


.mainContent,
.aside {
	display: block;
	width: 100%;
	margin-bottom: 15px;
}

@include breakpoint(lt-tablet) {
	.content {
		img[align="right"],
		img[align="left"],
		img.align-left,
		img.align-right {
			float: none;
			display: block;
			margin: 20px auto;
		}	
	}
}

@include breakpoint(tablet) {
	.mainContent {
		width: 66%;
		float: left;
	}

	.aside {
		width: 29%;
		float: right;
		margin-left: 4%;

		ul {
			margin-left: 17px;
		}
	}

	.crescent-third {
		width: 30%;
		margin-right: 5%;
		float: left;

		&.last {
			margin-right: 0;
		}
	}
}